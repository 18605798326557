.MenuWrapper{
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 80;
  pointer-events: none;
}
.MenuWrapper.Show{
  pointer-events: all;
}
.MenuWrapper.Show .MenuNav.Main{
  margin-left: 0;
}
.MenuWrapper.Show .MenuOverlay{
  opacity: 1;
}
.MenuNav{
  display: flex;
  flex-direction: column;
  width: calc(100%);
  max-width: 400px;
  background: #fff;
  height: 100vh;
  margin-left: -400px;
  transition: all 300ms ease-in-out;
  z-index: 85;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  border-right: solid 1px rgba(0,0,0,.05);
}

.MenuNav.Sub{
  z-index: 84;
  margin-left: -400px;
  background: #faf8f8;
}
.MenuNav.Main{
  z-index: 85;
}
.MenuNav.Subber{
  z-index: 83;
  margin-left: -400px;
  background: #f5f1f1;
}
.MenuNav.MenuItems{
  background: #FBF6F6;
}
.MenuNav.Sub.Show, .MenuNav.Subber.Show{
  margin-left: 0;
}
.MenuNav.Sub .MenuItems{
  background: #f7f1f1;
}
.MenuNav.Subber .MenuItems{
  background: #f4eded;
}


.MenuOverlay{
  flex: 1;
  transition: all 150ms ease-in-out;
  height: 100vh;
  opacity: 0;

  background: rgba(0,0,0,.4);
}
.MenuHeader{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 25px 25px 25px 30px;
  height: 115px;
  border-bottom: solid 1px #f2f2f2;
}
.MenuHeaderText{
  flex: 1;
  color: #e0197d;
  font-weight: bold;
  font-size: 26px;
  line-height: 25px;
  letter-spacing: -0.2px;
}
.MenuNavClose{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(0,0,0,.03);
  border-radius: 5px;
  font-size: 16px;
}
.MenuItems{
  flex: 1;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;

  background: #fefcfc;

  padding: 25px 5px;
}
.MenuItemDivider{
  height: 40px;
  width: calc(100%);
}
.MenuItem{
  display: flex;
  align-items: center;

  padding: 0 25px;
  height: 50px;
  color: #643950;
  border-bottom: solid 1px rgba(1,1,1,.025);
}
.MenuItem.Main{
  height: 50px;
}
.MenuItem.Selected{
  background: rgba(0,0,0,.02);
  color: #bc4c88 !important;
}
.MenuItem.Main .MenuItemTitle{
  font-size: 21px;
}
.MenuItemIcon{
  display: flex;
  width: 28px;
  height: 20px;
  align-items: center;
}
.MenuItem:hover{
  color: #e0197d;
}
.MenuItemTitle{
  flex: 1;
  font-weight: bold;
  font-size: 17px;

  cursor: pointer;
}
.MenuItemTitleBadge{
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #47bb3c;
  transform: translateY(-6px);
}
.MenuItemSubtitle{
  padding: 0 25px;
  font-weight: bold;
  color: #643950;
}
.MenuSocials{
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  padding: 0 25px;
}
.MenuSocialsItem{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #643950;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 10px;

  cursor: pointer;
}
.MenuSocialsItem:hover{
  filter: brightness(1.15);
}
.MenuSocialsItem.Fb{
  background: #3b5998;
}
.MenuSocialsItem.Ig{
  background-image: linear-gradient(to right bottom, #4f5bd5, #d62976, #feda75);
}
.MenuSocialsItem.Wa{
  background: #25D366;
}
.MenuSocialsItem.Ti{
  font-size: 14px;
  background-image: linear-gradient(to right bottom, #69C9D0, #EE1D52);
}
