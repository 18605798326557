.Product{
  min-height: 600px;
}
.ProductNavigationWrapper{
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding: 0 10px;
}
.ProductNavigation{
  display: flex;
  align-items: center;
  height: 36px;
  background: #fff;
  border-radius: 18px;
  overflow: hidden;
}
.ProductNavigationHome{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: calc(100%);
  padding-left: 4px;
  background: #ffe4f3;
  color: #e0197d;
}
.ProductNavigationItem{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-right: solid 1px rgba(0,0,0,.1);
}
.ProductNavigationItem:last-child{
  border-right: 0;
}
.ProductNavigationItemText{
  line-height: 30px;
  margin-right: 8px;
}
.ProductDetails{
  display: flex;
  width: calc(100%);
  margin-top: 25px;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: solid 1px rgba(0,0,0,.05);
}
.ProductDetailsRelated{
  position: relative;
  padding-bottom: 20px;
}
.ProductDetailsRelatedItems{
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 25px;
}
.ProductDetailsRelatedTitle{
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.2px;
  padding: 0 25px;
  margin-bottom: 15px;
}
.ProductDetailsImageWrapper{
  display: flex;
  margin-right: 25px;
  padding-right: 25px;
  border-right: solid 1px rgba(0,0,0,.05);
}
.ProductDetailsImageSide{
  margin-left: 15px;
}
.ProductDetailsImageSideItem{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  background: rgba(255,255,255,.5);
}
.ProductDetailsImageSideItem:hover{
  background: #FFF2F9;
}
.ProductDetailsImageSideItem>div{
  width: calc(100%);
  height: calc(100%);
  transition: all 150ms ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.ProductDetailsImageSideItem:hover>div{
  transform: rotate(-5deg) scale(1.1);
}
.ProductDetailsImageSideItem:first-child{
  margin-top: 0;
}
.ProductDetailsImageView{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background: rgba(255,255,255,.5);
  width: 575px;
  height: 500px;
  border-radius: 5px;
}
.ProductDetailsImage{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100%);
  width: calc(100%);
}
.ProductDetailsImage>img{
  max-width: calc(100%);
  max-height: calc(100%);
  transform: scale(1);
  transition: all 300ms ease-in-out;

  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-delay: 150ms;
  animation-name: productImageAnimation;
}
@keyframes productImageAnimation {
  0%{
    transform: scale(1)
  }
  50%{
    transform: scale(1.1) rotate(-2deg);
  }
  100%{
    transform: scale(1)
  }
}
.ProductDetailsContent{
  flex: 1;
  padding: 15px 0;
}
.ProductDetailsContentTitle{
  font-weight: bold;
  font-size: 23px;
  letter-spacing: -0.2px;
}
.ProductDetailsContentDescr{
  margin-top: 5px;
  line-height: 22px;
  font-size: 15px;
}
.ProductDetailsContentPriceView{
  display: block;
  margin-top: 40px;
}
.ProductDetailsContentPrice{
  display: flex;
  align-items: flex-end;
  font-weight: bold;
}
.ProductDetailsContentPrice.Pre .ProductDetailsContentPricePre{
  color: #5b5b5b;
  font-size: 10px;
}
.ProductDetailsContentPrice.Pre .ProductDetailsContentPriceAmount{
  color: #5b5b5b;
  font-size: 17px;
  line-height: 18px;
}
.ProductDetailsContentPrice.Pre .ProductDetailsContentPriceSubAmount{
  color: #5b5b5b;
  font-size: 9px;
  line-height: 21px;
}

.ProductDetailsContentPricePre{
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  color: #e0197d;

  margin-right: 5px;
}
.ProductDetailsContentPriceAmount{
  font-size: 26px;
  line-height: 23px;
  font-weight: bold;
  color: #e0197d;
}
.ProductDetailsContentPriceSubAmount{
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  color: #e0197d;
  margin-left: 3px;
}
.ProductDetailsContentAddWrapperView{
  display: flex;
  flex-direction: row;
}
.ProductDetailsContentAddWrapper{
  display: flex;
  border-top: solid 1px rgba(0,0,0,.05);
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  border-bottom: solid 1px rgba(0,0,0,.05);

  margin-bottom: 5px;
}
.ProductDetailsContentPriceFlipper{
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.1);
  border-radius: 18px;
  height: 36px;
  overflow: hidden;

  -webkit-user-select: none;
  user-select: none;
}
.ProductDetailsContentPriceFlipperItem{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100%);
  width: 44px;
  cursor: pointer;
}
.ProductDetailsContentPriceFlipperItem:first-child{
  padding-left: 5px;
}
.ProductDetailsContentPriceFlipperItem:last-child{
  padding-right: 5px;
}
.ProductDetailsContentPriceFlipperItem:hover{
  background: rgba(255,255,255,.2);
}
.ProductDetailsContentPriceFlipperText{
  padding: 0 8px;
  font-weight: bold;
  width: 30px;
  display: flex;
  justify-content: center;

}
.ProductDetailsContentAdd{
  margin-left: 25px;

  background: #e0197d;
  height: 36px;
  padding: 0 15px;
  border-radius: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  -webkit-user-select: none;
  user-select: none;
}
.ProductDetailsContentAdd.Circle{
  padding: 0;
  width: 36px;
  margin-left: 10px;
  border-radius: 50%;
}
.ProductDetailsContentAdd.Later{
  background: #e0197d;
}
.ProductDetailsContentAddText{
  margin-right: 10px;
  line-height: 30px;
  font-size: 15px;
}
.ProductDetailsContentInformation{
  display: block;
  margin-top: 15px;
  max-width: 600px;
}
.ProductDetailsContentInfoStock{
  margin-top: 25px;
  margin-bottom: 5px;
}
.ProductDetailsContentInfoStockText{
  color: #d63636;
  font-weight: bold;
  font-size: 15px;
}
.ProductDetailsContentInformationTitle{
  font-weight: bold;

  -webkit-user-select: none;
  user-select: none;
  font-size: 17px;
}
.ProductDetailsContentInformationText{
  margin-top: 5px;
  line-height: 22px;
  font-size: 17px;
}
.ProductDetailsContentInformationItems{
  display: block;
  max-width: 600px;
  margin: 0 -5px;
  margin-top: 10px;
  background: #3F333B;
  color: #fff;
  column-count: 2;
  column-rule-color: rgba(0,0,0,.05);
  column-rule-width: 1px;
  column-rule-style: solid;
  column-gap: 30px;
  padding: 12px 20px;
  padding-top: 17px;
  border-radius: 18px;
}
.ProductDetailsContentInformationItems.Hidden{
  display: none;
}
.ProductDetailsContentInformationItems.Hidden.Show{
  display: block;
}
.ProductDetailsContentInformationButtons{
  display: flex;
  align-items: center;
  margin: 0 -5px;
  margin-top: 5px;
}
.ProductDetailsContentInformationButton{
  display: flex;
  align-items: center;
  justify-content: center;

  background: #3F333B;
  height: 36px;
  padding: 0 15px;
  border-radius: 18px;
  color: #fff;
  display: flex;
  align-items: center;

  cursor: pointer;

  -webkit-user-select: none;
  user-select: none;
}
.ProductDetailsContentInformationButtonText{
  margin-right: 10px;
  line-height: 30px;
  font-size: 15px;
}
.ProductDetailsContentInformationItem{
  display: flex;
  width: calc(100%);
  margin-bottom: 5px;
}
.ProductDetailsContentInformationItemPre{
  flex: 1;
}
.ProductDetailsContentInformationItemValue{
  font-weight: bold;
}
.modalContentCartAnimationView{
  height: 0;
  margin: 0 -30px;
  background: #f6f6f6;
  width: calc(100% + 60px);
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: all 300ms ease-in-out;

  animation-name: productRollWrapper;
  animation-delay: 500ms;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.modalContentCartAnimationView.Closing{
  height: 120px;

  animation-name: productRollWrapperClose;
  animation-delay: 0ms;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.modalContentCartAnimation{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transform: translateX(-80px);

  position: absolute;
  top: 50%;
  margin-top: -40px;
  left: 0;
  z-index: 20;

  animation-name: productRoll;
  animation-delay: 800ms;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.modalContentCartAnimation>img{
  max-width: calc(100%);
  max-height: calc(100%);
}
.modalContentCartAnimationLine{
  display: block;
  width: 100px;
  height: 0;
  background: #e0197d;

  position: absolute;
  left: -10px;
  bottom: 0;

  animation-name: productRollFollower;
  animation-delay: 800ms;
  animation-duration: 1.9s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.modalContentCartAnimationLine.Top{
  top: 0;
}
.modalContentCartAnimationLine:not(.Top){
  bottom: 0;
}

@keyframes productRollWrapper {
  0% {
    height: 0;
  }
  100% {
    height: 120px;
  }
}
@keyframes productRollWrapperClose {
  0% {
    height: 120px;
  }
  100% {
    height: 0;
  }
}

@keyframes productRoll {
  0% {
    transform: translateX(-80px) rotate(0deg);
  }
  100% {
    transform: translateX(700px) rotate(342deg);
  }
}
@keyframes productRollFollower {
  0% {
    transform: translateX(-80px);
  }
  5% {
    height: 0;
  }
  15% {
    height: 2px;
  }
  65% {
    height: 2px;
  }
  75% {
    height: 0;
  }
  100% {
    transform: translateX(650px);
  }
}
