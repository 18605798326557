.Login{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 800px;

  width: calc(100%);
  max-width: 1500px;
  margin: 0 auto;
  background: rgba(0,0,0,.1);
}
.LoginImageView{
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  flex: 1;

  position: relative;
}
.LoginImageView::after{
  content: "";
  background: linear-gradient(to top left, rgba(0,0,0,.5), rgba(0,0,0,0));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 22;

  transition: all 300ms ease-in-out;
}
.Login.Signup .LoginImageView::after{
  /* background: linear-gradient(to top left, rgba(85,175,92,.5), rgba(85,175,92,0)); */
}
.LoginContent{
  display: flex;
  width: calc(100%);
  max-width: 600px;
  min-height: 500px;
  background: #fff;
  flex-direction: column;
  position: relative;
}
.LoginContent.Loading .LoginForm{
  filter: grayscale(.5);
}
.LoginLoadingOverlay{
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}
.LoginLoadingOverlay.NoBackground{
  background: none;
}

.LoginLoadingOverlay.Dark{
  background: rgba(255,255,255,.1);
  margin-bottom: 15px;
}
.LoginLoadingOverlay.Dark.Inline{
  padding: 10px;
}
.LoginLoadingOverlay.Dark .LoginLoading{
  background: rgba(255,255,255,.75);
}
.LoginLoadingOverlay.Animated{
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease-in-out;
}
.LoginLoadingOverlay.Animated.Loading{
  opacity: 1;
  pointer-events: all;
}
.LoginLoadingOverlay.Inline{
  position: static;
  border-radius: 25px;
  padding: 15px;
}
.LoginLoadingCenter{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.LoginLoading{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}
.LoginLoadingAnimation{
  width: 80px;
}
.LoginForm{
  display: flex;
  flex: 1;
  flex-direction: column;
  width: calc(100%);
  transition: all 300ms ease-in-out;

  padding: 45px 60px;
}
.LoginFormItems{
  flex: 1;
}
.LoginFormVerifyWrapper{
  margin-bottom: 25px;
}
.LoginFormVerify{
  padding: 0 5px;
}
.LoginFormVerifyTitle{
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  color: #e0197d;
}
.LoginFormVerifySubtitle{
  margin-top: 8px;
  padding: 0 3px;
}
.LoginDivider{
  margin-top: 25px;
  margin-bottom: 20px;
  width: calc(100%);
  height: 1px;
  background: #f2f2f2;
}
.LoginFormHeader{
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 5px;
  height: 50px;
  padding-bottom: 25px;
  margin-bottom: 25px;

  border-bottom: solid 1px #f2f2f2;
}
.LoginFormHeaderTitle{
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
}
.LoginFormHeaderTitle b{
  color: #e0197d;
}
.LoginFormItemTwin{
  margin-bottom: 20px;
  display: flex;
}
.LoginFormItemTwin .LoginFormItem{
  flex: 1;
  margin-bottom: 0;
}
.LoginFormItemTwin .LoginFormItem:first-child{
  margin-right: 15px;
}
.LoginFormItem{
  display: block;
  width: calc(100%);
  margin-bottom: 20px;
}
.LoginFormItemPre{
  display: block;
  font-weight: bold;
  padding: 0 5px;
}
.LoginFormItemInputView{
  display: flex;
  align-items: center;
  background: #f2ecec;
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
}
.LoginFormItemInput{
  width: calc(100%);
  background: transparent;
  height: 36px;
  padding: 0 15px;
}
.LoginFormOptions{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 1px #f2f2f2;
}
.LoginFormOptionsButton{
  flex: 1;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 20px;
  background: #e0197d;
  color: #fff;
  cursor: pointer;
  border-radius: 5px 18px 18px 5px;
}
.LoginFormOptionsButton.Margined{
  margin-top: 10px;
}
.LoginFormOptionsButton.Left{
  border-radius: 18px 5px 5px 18px;
}
.LoginFormOptionsButton.Social{
  margin-bottom: 15px;
  background: #f2f2f2;
  color: #3b3b3b;
}
.LoginFormOptionsButton.Forgot{
  background: #f2f2f2;
  color: #3b3b3b;
  margin-right: 15px;
}
.LoginFormOptionsButton.Forgot:hover{
  background: #f6f6f6;
}
.LoginFormOptionsButton.LoginBack{
  background: #f2f2f2;
  color: #3b3b3b;
  margin-right: 15px;
}
.LoginFormOptionsButton.LoginBack:hover{
  background: #f6f6f6;
  margin-right: 15px;
}
.LoginFormSignupView{
  position: relative;
  padding: 30px;
  padding-top: 25px;
  background: #55af5c;
  border-radius: 5px;
  margin: 0 -30px;
  margin-top: 60px;
  margin-bottom: -15px;
}
.LoginFormSignupView .LoginFormOptionsButton{
  background: rgba(255,255,255,.95);
  color: #314232;
}
.LoginFormSignupView .LoginFormOptionsButton:hover{
  background: #fff;
}
.LoginFormSignupView .LoginFormHeaderTitle, .LoginFormSignupView .LoginFormHeaderTitle b{
  color: #fff;
}
.LoginFormSignupView .LoginFormHeader{
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom-color: rgba(255,255,255,.3);
}
.LoginFormOptionsButton.Social:hover{
  background: #f6f6f6;
}
.LoginFormOptionsButton.Social:last-child{
  margin-bottom: 0;
}
.LoginFormOptionsButton.Social .LoginFormOptionsButtonText{
  margin-left: 20px;
}
.LoginFormOptionsButtonText{
  flex: 1;
  font-weight: bold;
}
.LoginFormOptionsButton.Forgot .LoginFormOptionsButtonText b{
  color: #e0197d;
}
.LoginFormOrView{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}
.LoginFormOr{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  font-size: 12px;
  font-weight: bold;
}
.LoginFormOrDivider{
  flex: 1;
  height: 1px;
  background: #f2f2f2;
}
.LoginResponseWrapper{
  height: 0;
  overflow: hidden;
}
.LoginResponseWrapper.Show{
  height: auto;
}
.LoginResponse{
  display: flex;
  flex-direction: row;
  padding: 15px 35px;
  background: #d53e3e;
  border-radius: 2px;
  padding: 15px 35px;
}

.LoginResponseDot{
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #e0197d;
  margin-top: 5px;
}
.LoginResponseDetails{
  flex: 1;
  margin-left: 15px;
}
.LoginResponseDetailsText{
  font-weight: 500;
  color: #fff;
}
.LoginFormDisclaimerWrapper{
  padding: 0 5px;
}
.LoginFormDisclaimer{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3b3b3b;
}
.LoginFormDisclaimer:hover{
  color: #5b5b5b;
}
.LoginFormDisclaimerWrapper.Selected .LoginFormDisclaimerCheck::before{
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #47bb3c;
}
.LoginFormDisclaimerText>a{
  color: #e0197d;
  text-decoration: underline;
}
.LoginFormDisclaimerCheck{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background: #f2f2f2;
  margin-right: 10px;
}
.LoginFormDisclaimerText{
  flex: 1;
}
