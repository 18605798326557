.Empty{
  height: 700px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 40px 0;
  overflow: hidden;
}
.EmptyModal{
  display: flex;
  flex-direction: column;
  height: calc(100%);

  max-width: 1530px;
  padding: 0 15px;
  position: relative;
  z-index: 25;
  pointer-events: none;
}
.EmptyModalTitle{
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.5px;
  opacity: .75;
  padding: 0 5px;
}
.EmptyModalTitleDivider{
  flex: 1;
}
.EmptyModalText{
  font-size: 30px;
}

.EmptyGlobe{
  width: 100vw;
  height: 100vw;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: -50vw;
  border-radius: 50%;
  margin-bottom: -750px;
  z-index: 10;
  overflow: hidden;

  margin-left: -50%;
  animation-name: rotate;
  animation-duration: 5s;
  animation-duration: 200ms;
  animation-timing-function: linear;
}
@keyframes rotate {
  from {
    transition: rotate(0deg);
  }
  to {
    transition: rotate(360deg);
  }
}
.EmptyGlobe>img{
  width: calc(100%);
  height: calc(100%);
}
