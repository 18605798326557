.CustomerService{
  height: calc(100vh - 161px - 35px);
}
.CustomerService .FixedWidth{
  height: calc(100%);
}
.CustomerServiceWrapper{
  display: flex;
  width: calc(100%);
  height: calc(100%);
  position: relative;
}
.CustomerServiceSide{
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 25px;
  margin-right: 10px;
  padding-right: 25px;

  -webkit-user-select: none;
  user-select: none;
}
.CustomerServiceHeader{
  display: flex;
  flex: 1;
  margin-right: 25px;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 20px 25px;
}
.CustomerServiceHeaderTitle{
  font-weight: bold;
  color: #2b2b2b;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.2px;
}
.CustomerServiceHeaderAnimationView{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 36px;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 26;
  overflow: hidden;
  border-radius: 5px 5px 50% 50%;
}
.CustomerServiceHeaderAnimationView.Typing .CustomerServiceHeaderAnimation{
  margin-bottom: -10px;
  width: 50px;
}
.CustomerServiceHeaderAnimation{
  position: absolute;
  transition: all 500ms ease-in-out;
}
.CustomerServiceChat{
  display: flex;
  flex-direction: column;
  width: 500px;
  background: #fff;
  border-radius: 0 0 5px 5px;
  height: calc(100%);
  overflow: hidden;
  z-index: 25;
}
.CustomerServiceChatHeader{
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 50px;
  background: rgba(0,0,0,.01);
  border-bottom: solid 1px #efefef;
}
.CustomerServiceChatHeaderAI{
  display: flex;
  align-items: center;
  font-weight: bold;
  background: #47bb3c;
  color: #fff;
  height: 20px;
  font-size: 10px;
  border-radius: 10px;
  padding: 0 8px;
}
.CustomerServiceChatHeaderAI::before{
  display: inline-block;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
  margin-right: 5px;
}
.CustomerServiceChatHeaderTitle{
  flex: 1;
  margin-left: 20px;
  font-weight: bold;
  font-size: 16px;
}
.CustomerServiceChatContent{
  flex: 1;
  display: block;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.CustomerServiceChatContentItems{
  padding: 20px 25px;
}
.CustomerServiceChatMessageView{
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 65px;
  border-top: solid 1px #efefef;
}
.CustomerServiceChatMessageInput{
  flex: 1;
  display: flex;
  padding: 0 15px;
  height: 36px;
  background: rgba(0,0,0,.05);
  border-radius: 5px;
  margin-right: 15px;
}
.CustomerServiceChatMessageButton{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e0197d;
  color: #fff;
  width: 36px;
  height: 36px;
  font-size: 12px;
  border-radius: 5px;
}
.CustomerServiceChatMessageButton.Disabled{
  background: #5b5b5b;
}
.ChatMessageItemWrapper{
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.ChatMessageItemWrapper.Grouped{
  margin-bottom: 5px;
  padding-right: 42px;
}
.ChatMessageItemWrapper.assistant{
  justify-content: flex-start;
}
.ChatMessageItemWrapper.user{
  justify-content: flex-end;
}
.ChatMessageItemWrapper.user .ChatMessageItem{
  align-items: flex-end;
}
.ChatMessageItemWrapper.user .ChatMessageItemText{
  background: #e0197d;
  color: #fff;
  border-radius: 10px 10px 2px 10px;
}
.ChatMessageItemWrapper.user .ChatMessageItemAvatar{
  margin-left: 5px;
  margin-right: 0;
}
.ChatMessageItem{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 350px;
}
.ChatMessageItemAvatarWrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.ChatMessageItemAvatar{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(0,0,0,.05);
  border-radius: 50%;
  margin-right: 5px;

  position: relative;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.ChatMessageItemText.Small{
  padding: 0px 8px;
}
.ChatMessageItemText .LoginLoading{
  width: auto;
  height: auto;
  background: transparent;
}
.ChatMessageItemText .LoginLoadingAnimation{
  width: 40px;
  height: 40px;
}
.ChatMessageItemTextView{
  flex: 1;
}
.ChatMessageItemLink{
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin: 0 5px;
  margin-top: 10px;

  height: 40px;
  background: #fff;
  color: #e0197d;
  border: solid 1px #e0197d;
  border-radius: 5px;
  cursor: pointer;
}
.ChatMessageItemLink:hover{
  border-color: #ef3391;
  color: #ef3391;
}
.ChatMessageItemLinkText{
  flex: 1;
  font-weight: bold;
  margin-left: 8px;
}
.ChatMessageItemText{
  flex: 1;
  line-height: 22px;
  padding: 10px 15px;
  background: rgba(0,0,0,.05);
  border-radius: 10px 10px 10px 2px;
}
.ChatMessageItemDate{
  padding: 0 50px;
  margin-top: 5px;
  font-size: 11px;
}
.CustomerServiceContent{
  display: block;
  margin-top: 25px;
  padding-left: 15px;
}
.FaqItemWrapper{
  display: flex;
  flex-direction: column;
  border-radius: 0;
  border-bottom: solid 1px rgba(0,0,0,.05);
}
.FaqItemWrapper:hover{
  background: rgba(255,255,255,.4)
}
.FaqItemWrapper.Show{
  background: rgba(255,255,255,.75);
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}
.FaqItemWrapper.Back{
  opacity: .75;
}
.FaqItemWrapper.Show .FaqItemQuestion{
  color: #e0197d;
}
.FaqItemWrapper.Show .FaqItemAnswer{
  display: block;
}
.FaqItemQuestionView{
  display: flex;
  align-items: center;

  padding: 15px 25px;
  cursor: pointer;
}
.FaqItemQuestion{
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: bold;
  border-radius: 5px;
  font-size: 16px;
}
.FaqItemAnswer{
  display: none;
  padding: 15px 25px;
}
.NoShowButton{
  display: none;
}
