.CustomerAI{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 45;
  padding: 15px;

  pointer-events: none;
}
.CustomerAI .FullOverlay{
  width: 100vw;
  height: 100vh;
  position: absolute;
  bottom: 0;
  right: 0;
  scale: 1;
  z-index: 20;
  transform-origin:  bottom right;
  opacity: 0;
  background: linear-gradient(to left top, rgba(0,0,0,.6), rgba(0,0,0,.2), rgba(0,0,0, 0));

  pointer-events: none;
  animation: none !important;
  transition: all 300ms ease-in-out;
	transition-delay: 600ms;
}
.CustomerAI.Full .FullOverlay{
  transition-delay: 0ms;
  opacity: 0;
}
.CustomerAI.Show .FullOverlay{
  opacity: 1;
}
.CustomerAI.Show{
  pointer-events: all;
}
.CustomerAI.Full.Show .FullOverlay{
  opacity: 1;
  pointer-events: all !important;
}
@keyframes customerAIOverlay{
  0%{
    scale: 0;
  }
  50%{
    scale: 1.1;
  }
  100%{
    scale: 1;
  }
}
.CustomerAIButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50% 50% 5px 50%;
  background: #eb1e85;
  color: #fff;
  font-size: 18px;
  position: relative;
  z-index: 23;

  cursor: pointer;
  scale: 0;
  pointer-events: all;

	animation-delay: 1500ms;
	animation-name: introAIButton;
	animation-duration: 600ms;
	animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.CustomerAIButton.Loading .CustomerAIButtonIcon{
	animation-name: aiLoading;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.CustomerAIButtonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes aiLoading{
  0%{
    scale: 1;
  }
  33%{
    scale: 1.21;
    transform: rotate(30deg);
  }
  66%{
    scale: 1.2;
    transform: rotate(-30deg);
  }
  100%{
    scale: 1;
  }
}
@keyframes introAIButton{
  0%{
    scale: 0;
  }
  50%{
    scale: 1.1;
  }
  100%{
    scale: 1;
  }
}
@keyframes introAIChat{
  0%{
    scale: 0;
  }
  50%{
    scale: 1.1;
  }
  100%{
    scale: 1;
  }
}
.CustomerAIButton:hover{
  background: #ef3391;
}
.CustomerAI.New .CustomerAIButton{
  background: #eab127;
  color: #2b2b2b;
}
.CustomerAIButtonBadge{
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #2b2b2b;
  border: solid 5px #eab127;
  position: absolute;
  top: 0;
  scale: 0;
  right: 0;

	animation-delay: 500ms;
	animation-name: badgeNew;
	animation-duration: 500ms;
	animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes badgeNew{
  0%{
    scale: 0;
  }
  50%{
    scale: 1.1;
  }
  100%{
    scale: 1;
  }
}
.CustomerAIButtonChatsWrapper{
  position: relative;
  pointer-events: all;
}
.CustomerAIButtonChatsClose{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3f3339;
  color: #fff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  scale: 0;

	animation-delay: 2000ms;
	animation-name: introAIButton;
	animation-duration: 500ms;
	animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  position: absolute;
  top: 6px;
  right: 8px;
  z-index: 25;
}
.CustomerAIButtonChatsClose:hover{
  background: #4e4147;
}
.CustomerAIButtonChats{
  display: flex;
  align-items: center;
  padding: 10px 10px;
  padding-right: 75px;
  background: #fff;
  position: relative;
  z-index: 22;
  border-radius: 10px;
  scale: 0;
  transform-origin:  bottom right;

	animation-delay: 1750ms;
	animation-name: introAIButton;
	animation-duration: 600ms;
	animation-iteration-count: 1;
  animation-fill-mode: forwards;

  margin-right: -5px;
  margin-bottom: -30px;
  border: solid 1px rgba(0,0,0,.05);
}
.CustomerAIButtonChatsAvatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #efefef;
  background-size: cover;
  background-position: center;

  margin-right: 15px;
}
.CustomerAIButtonChatsChat{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.CustomerAIButtonChatsChatText{
}
.CustomerAIButtonChatsChatDate{
  margin-top: 3px;
  font-size: 11px;
}

.CustomerAIMessages{
  display: flex;
  align-items: center;

  /* padding: 10px 10px; */

  width: 400px;
  height: 500px;
  background: #fff;
  position: relative;
  z-index: 22;
  border-radius: 10px;
  translate: 0 75px;
  scale: 0;
  rotate: -20deg;
  transform-origin:  bottom right;
  transition: all 300ms ease-in-out;
  transition-delay: 50ms;
  overflow: hidden;

  margin-right: -5px;
  margin-bottom: -10px;
  border: solid 1px rgba(0,0,0,.05);

  pointer-events: none;
}
.CustomerAIMessages.Show{
  rotate: 0deg;
  scale: 1;
  translate: 0 0;
  pointer-events: all;
}
.CustomerAIHeaderClose{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	width: 32px;
	border-radius: 50%;
  margin-left: 10px;
  margin-right: -15px;
	cursor: pointer;
	background: #efefef;
}
.ChatResetView{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}
.ChatResetPre{
  margin-right: 10px;
  font-size: 14px;
}
.ChatReset{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  background: #efefef;
  border-radius: 12px;
  padding: 0 10px;
  font-size: 10px;
  cursor: pointer;
}
.ChatResetText{
  font-weight: bold;
  margin-right: 8px;
  font-size: 10px;
}
