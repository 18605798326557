.HeaderWrapper{
  width: calc(100%);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 44;
  border-bottom: solid 1px rgba(0,0,0,.05);
}
.HeaderSub{
  background: #fff;
  height: 35px;
  position: relative;
  z-index: 30;
  border-bottom: solid 1px #f6f6f6;
}
.HeaderSubLeft{
  flex: 1;
  padding-left: 12px;
}
.HeaderSubNav{
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  margin-right: -12px;
}
.HeaderSubNavItem{
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 12px;

  font-weight: 500;
  color: #3b3b3b;

  cursor: pointer;
}
.HeaderSubNavItem>b{
  color: #e0197d;
  margin-left: 5px;
}
.HeaderSubNavItem:hover{
  color: #e0197d;
}
.HeaderNav{
  background: #fff;
  height: 70px;
  position: relative;
  z-index: 29;
}
.HeaderNavWidth{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(100%);
  width: calc(100%);
  background: #fff;
  max-width: 1400px;
  padding: 0 10px;
  margin: 0 auto;
}
.HeaderNavLogoWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 6px 18px 6px 8px;
  margin-left: -5px;
  border-radius: 5px;
  margin-right: 7px;
}
.HeaderNavLogoWrapper:hover{
  background: #f2f2f2;
}
.HeaderNavLogoImage{
  height: 50px;
}
.HeaderNavLogoImage>img{
  height: calc(100%);
}
.HeaderNavLogoDetails{
  margin-left: 20px;
}
.HeaderNavLogoDetailsName{
  font-weight: bold;
  color: #e0197d;
  white-space: nowrap;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.2px;
}
.HeaderNavLogoDetailsSubtitle{
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.HeaderNavLogoDetailsSubtitleFlag{
  height: 10px;
  border-radius: 2px;
  margin-right: 5px;
}
.HeaderNavLogoDetailsSubtitleText{
  font-weight: 500;
  line-height: 14px;
}
.HeaderNavOptions{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: calc(100%);
  padding-left: 5px;
  position: relative;
  z-index: 30;
  border-left: solid 1px #f2f2f2;
}
.HeaderNavOptionsMenu{
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: calc(100%);

  font-weight: bold;
  font-size: 19px;
  color: #643950;

  cursor: pointer;
}
.HeaderNavOptionsMenu.Selected{
  color: #e0197d;
}
.HeaderNavOptionsMenu:hover{
  color: #e0197d;
}
.HeaderNavOptionsMenu *:not(span){
  margin-left: 10px;
  margin-bottom: -4px;
  font-size: 17px;
}
.HeaderNavSearchWrapper{
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-left: solid 1px #f2f2f2;
  padding-left: 20px;
  position: relative;
}
.HeaderNav .HeaderNavSearchWrapper::before{
  content: "";
  position: absolute;
  top: -22px;
  right: -40px;
  bottom: -15px;
  left: -40px;
  background: #fff;
  z-index: 26;
}
.HeaderNavSearch{
  display: flex;
  flex-direction: row;
  background: #f2ecec;
  height: 36px;
  border-radius: 20px;
  overflow: hidden;
  width: 300px;
  max-width: 450px;
  transition: all 300ms ease-in-out;

  position: relative;
  z-index: 27;
}
.HeaderNavSearchInput{
  flex: 1;
  background: transparent;
  transition: all 300ms ease-in-out;
  padding: 0 20px;
  font-weight: 500;
  width: calc(100%);
}
.HeaderNavSearchWrapper:focus-within .HeaderNavSearch{
  width: calc(100%);
}
.HeaderNavSearchWrapper:focus-within .searchItemsView{
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 10px 50px rgba(0,0,0,.05);
}
.HeaderNavSearchButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}
.HeaderNavButtons{
  display: flex;
  align-items: center;
  border-left: solid 1px #f2f2f2;
  margin-left: 20px;
  padding-left: 0px;
  padding-right: 5px;
  position: relative;
  z-index: 28;
}
.HeaderNavButton{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  font-size: 18px;
  border-radius: 50%;
  color: #643950;
  margin-left: 10px;
  position: relative;

  background-size: cover;
  background-position: center;
}
.HeaderNavButtonBadge{
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #47bb3c;
  position: absolute;
  top: 6px;
  right: 6px;
}
.HeaderNavButtonCount.Small{
  width: 10px;
  height: 10px;
  top: 3px;
  right: 3px;
}
.HeaderNavButtonCount{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #febe23;
  font-weight: bold;
  color: #3b3b3b;
  line-height: 19px;
  text-align: center;
  font-size: 10px;

  position: absolute;
  top: -3px;
  right: -3px;
  transform: scale(0) rotate(0deg);

  animation-name: countAnimation;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes countAnimation {
  0% {
    transform: scale(0) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
.HeaderNavAvatar{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-color: #efefef;
}
.HeaderNavButton.Selected{
  color: #e0197d;
}
.HeaderNavButton:hover{
  color: #e0197d;
  background: #efefef;
}
.HeaderMessages{
  white-space: nowrap;
  display: flex;
  flex-direction: row;
}
.HeaderMessageItem{
  display: flex;
  align-items: center;
  line-height: 14px;
  margin-right: 30px;
}
.searchItemsViewWrapper{
}
.searchItemsView{
  display: block;
  width: calc(100%);
  opacity: .9;

  position: absolute;
  width: calc(100%);
  max-width: 490px;
  top: 50px;
  right: -20px;
  transform: translateY(-500px);
  transition: all 300ms ease-in-out;

  max-height: 400px;
  border-radius: 0 0 25px 25px;
  border: solid 1px #efefef;
  border-top: 0;

  padding: 15px;
  padding-top: 0;
  overflow-x: hidden;
  overflow-y: scroll;

  background: #fff;
  transition: all 300ms ease-in-out;

  z-index: 25;
}
.searchItemWrapper{
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: #fff;
  border-bottom: solid 1px #f6f6f6;
  position: relative;
  z-index: 19;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}
.searchItemWrapper:hover{
  border-radius: 5px;
  background: #FDF9F9;
  z-index: 20;
}
.searchItemWrapperText{
  font-size: 14px;
  color: #7b7b7b;
  padding: 15px 20px;
  text-align: center;
}
.searchItemProductImage{
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin-right: 20px;
  background-size: contain;
  border: solid 5px transparent;
  background-repeat: no-repeat;
  background-color: #f2f2f2;
  background-position: center;
}
.searchItemBlogImage{
  width: 100px;
  height: 70px;
  border-radius: 5px;
  margin-right: 15px;
  background-size: cover;
  background-color: #efefef;
  background-position: center;
}
.searchItemStoreImage{
  width: 70px;
  height: 70px;
  border-radius: 5px;
  margin-right: 15px;
  background-size: cover;
  background-color: #efefef;
  background-position: center;
}
.searchItemDetails{
  flex: 1;
  padding: 5px 0;
  overflow: hidden;
}
.searchItemDetailsTitle{
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchItemDetailsSubtitle{
  margin-top: 2px;
  opacity: .75;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchItemDetailsSubtitle.Upper{
  font-size: 11px;
  font-weight: bold;
  color: #e0197d;
}
.searchItemDetailsDate{
  margin-top: 2px;
  opacity: .75;
  font-weight: bold;
  font-size: 14px;
}
.searchItemIcon{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;
  margin-left: 10px;
  background: #efefef;
  border-radius: 50%;
}
.searchItemInfo{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100px;
  height: calc(100%);
  margin-left: 20px;
  border-left: solid 1px #f2f2f2;
}
.searchItemInfoPre{

}
.searchItemInfoValue{
  margin-top: 2px;
  font-weight: bold;
  color: #e0197d;
}
.searchItemDetailsPhoneView{
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.searchItemDetailsPhone{
  flex: 1;
  font-weight: bold;
  margin-top: -1px;
}
.searchItemDetailsPhoneIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 11px;
  border-radius: 50%;
  margin-right: 5px;
  background: #47bb3c;
  color: #fff;
}
.searchItemHeader{
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background: #3b3b3b;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
}
