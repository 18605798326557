.Stores{
  display: flex;
  height: calc(100vh - 161px - 35px);
  position: relative;
}
.StoresWhite{
  width: calc(50vw - 315px);
  height: calc(100%);
  background: #fff;
}
.StoresWrapper{
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
}
.Stores .FixedWidth{
  display: flex;
  height: calc(100%);
  padding: 0 0;
}
.StoresItemsHeader{
  display: flex;
  flex-direction: row;
  padding: 20px 25px;
  border-bottom: solid 1px rgba(0,0,0, .1);
}
.StoresItemsHeaderTitle{
  font-weight: bold;
  color: #2b2b2b;
  font-size: 25px;
  letter-spacing: -0.5px;
  letter-spacing: -0.2px;
}
.StoresItemsItems{
  flex: 1;

  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px;
}
.StoresMap{
  flex: 1;
  height: calc(100%);
}
.StoreMarkerWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -100%);
}
.StoresItems{
  display: flex;
  overflow: hidden;
  width: calc(100%);
  height: calc(100%);
  max-width: 450px;
  flex-direction: column;
  pointer-events: all;
  background: #fff;
  padding-left: 10px;
}
.StoreItemWrapper{
  display: flex;
  flex-direction: column;
  padding: 10px 10px;

  cursor: pointer;
  border-radius: 5px;
  border-bottom: solid 1px rgba(0,0,0,.05);
}
.StoreItemWrapper.Selected{
  background: #FFF2F9;
  border-bottom-color: transparent;
}
.StoreItemWrapper:hover{
  background: #FFF2F9;
  border-bottom-color: transparent;
}
.StoreItemHours{
  padding-left: 85px;
  margin-top: 5px;
  padding-right: 40px;
}
.StoreItemHoursTitle{
  font-weight: bold;
  margin-top: 10px;
  font-size: 15px;
}
.StoreItemHour{
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.StoreItemHourKey{
  width: 125px;
  color: #5b5b5b;
  font-size: 15px;
}
.StoreItemHourValue{
  flex: 1;
  color: #e0197d;
  text-align: right;
  font-size: 15px;
}
.StoreItem{
  display: flex;
  width: calc(100%);
  padding-right: 5px;
  flex-direction: row;
  align-items: center;
}
.StoreItemDetails{
  flex: 1;
  margin-right: 15px;
  margin-left: 15px;
}
.StoreItemDetailsTitle{
  font-weight: bold;
  font-size: 17px;
}
.StoreItemDetailsAddress{
  margin-top: 2px;
  color: #5b5b5b;
  font-size: 15px;
}
.StoreItemDetailsPhoneView{
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 12px;
  color: #3b3b3b;
}
.StoreItemDetailsPhone{
  margin-left: 5px;
  font-weight: bold;
  font-size: 14px;
  margin-top: -1px;
}
.StoreItemImage{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 60px;
  border-radius: 5px;
  background-color: #efefef;
  background-size: cover;
  background-position: center;
}
.StoreItemImage>img{
  width: 40px;
  height: 40px;
}
.StoreMarker{
  display: flex;
  flex-direction: row;
  border-left: solid 2px #e0197d;

  background: #fff;
  padding: 10px;
  position: relative;
}
.StoreMarkerImage{
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #efefef;
}
.StoreMarkerImage>img{
  width: calc(100%);
  height: calc(100%);
}
.StoreMarkerDetails{
  flex: 1;
  margin-lefT: 10px;
}
.StoreMarkerDetailsTitle{
  font-weight: bold;
  white-space: nowrap;
  font-size: 14px;
}
.StoreMarkerDetailsAddress{
  margin-top: 3px;
  font-size: 12px;
  white-space: nowrap;
  color: #5b5b5b;
}
.StoreMarkerLeg{
  height: 25px;
  width: 4px;
  background: #e0197d;
  border-radius: 0 0 2px 2px;
}
