.Vacancies{
  min-height: 600px;
  position: relative;
}
.VacanciesHeader{
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  padding-top: 75px;
  position: relative;
  z-index: 4;
  border-radius: 0 0 10px 10px;
  /* background: rgba(0,0,0,.04); */
  background: #009f2e;
  position: relative;
}
.VacanciesHeaderTitle{
  font-weight: bold;
  font-size: 35px;
  color: #fff;
  letter-spacing: -0.2px;
  max-width: 600px;
  line-height: 35px;
  margin-left: -5px;
}
.VacanciesHeaderTitle b{
  display: inline-block;
  padding: 0 6px;
  height: 42px;
  line-height: 37px;
  background: #e0197d;
  color: #fff;
  border-radius: 5px;
}

.VacancyItem{
  display: inline-block;
  vertical-align: bottom;
  color: inherit;

  width: calc(100%);
  max-width: 260px;

  margin: 5px;
  background: rgba(255,255,255,.6);

  overflow: hidden;
  position: relative;
  border-radius: 5px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: all 150ms ease;
  -moz-transition: all 150ms ease;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;

  cursor: pointer;
}
.VacancyItemType{
  font-weight: bold;
  font-size: 11px;
  color: #e0197d;
  margin-bottom: 2px;
}
.VacancyItem:active{
  transform: scale(0.98);
  box-shadow: 0 5px 40px rgba(0,0,0,.1);
}
.VacancyItem:hover{
  background: rgba(255,255,255,1);
}
.VacancyItemDetails{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(100%);
  height: calc(100%);
  padding: 25px;
}
.VacancyItemIcon{
  font-size: 25px;
  margin-bottom: 10px;
}
.VacancyItemTitle{
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.VacancyItemDescr{
  margin-top: 5px;
  opacity: .75;
}
.VacancyItemOptions{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.VacancyItemOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.VacanciesCarouselContainer{
  width: 100%;
  height: 500px;
  margin-top: 20px;
  border-radius: 8px;
}
