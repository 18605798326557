.Products{
  min-height: 600px;
  padding-bottom: 25px;
}
.ProductsEmptyWrapper{
  flex: 1;
  display: flex;
  width: calc(100%);
  height: calc(100%);
  overflow: hidden;
}
.ProductsEmpty{
  position: absolute;
  top: 35px;
  left: 35px;
  z-index: 10;
  padding: 0 5px;
}
.ProductsEmptyTitle{
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: -0.5px;
  opacity: .75;
}
.ProductsEmptySubtitle{
  opacity: .75;
  padding: 0 2px;
}
.ProductsHeaderImage{
  width: calc(100%);
  height: calc(100%);
  background: #e0197d;
}
.ProductsItems{
  position: relative;
  min-height: 625px;
  flex: 1;
  transition: all 150ms ease-in-out;
}
.ProductsItemsItems{
  padding: 25px;
  padding-top: 5px;
}
.ProductsHeader{
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: calc(100%);
  height: 10px;
  background-size: cover;
  background-position: center;
  background-color: rgba(0,0,0,.1);
  border-radius: 0 0 5px 5px;

  overflow: hidden;
}
.ProductsWrapper{
  margin-top: 25px;
  display: flex;
}
.ProductsSide{
  width: calc(100%);
  max-width: 300px;

  padding-right: 25px;

  padding-top: 0;

  border-right: solid 1px rgba(0,0,0,.1);
}
.ProductsSideHeader{
  padding: 0 20px;

  border-bottom: solid 1px #efefef;

  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.ProductsSideHeaderTitle{
  flex: 1;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: -0.5px;
}
.ProductsItemsWrapper{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.ProductsSideItems{
  margin-top: 15px;
  padding-bottom: 15px;
  margin-right: -25px;
  border-bottom: solid 1px rgba(0,0,0,.05)
}
.ProductsSideCategoryItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0 35px;
  padding-right: 25px;
  font-size: 18px;
  border-radius: 2px;

  cursor: pointer;
}
.ProductsSideCategoryItem.SelectedBall{
  color: #e0197d;
}
.ProductsSideCategoryItem.SelectedBall::before{
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  background: #e0197d;
}
.ProductsSideCategoryItem:hover .ProductsSideCategoryItemTitle{
  opacity: 1;
}
.ProductsSideCategoryItem.Selected{
  background: rgba(0,0,0,.05);
  color: #e0197d;
}
.ProductsSideHeaderSubtitle{
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 0 25px;
  padding-right: 0;
  font-weight: bold;
  font-size: 20px;
  opacity: .95;
  letter-spacing: -0.3px;
}
.ProductsSideHeaderSubtitleText{
  flex: 1;
}
.ProductsSideHeaderSubtitle.Selectable{
  opacity: .85;
}
.ProductsSideHeaderSubtitle.Selectable:hover{
  opacity: 1;
}
.ProductsSideHeaderSubtitle.Selected{
  color: #e0197d;
}
.ProductsSideCategoryItemTitle{
  flex: 1;
  font-weight: bold;
  font-size: 18px;
  opacity: .75;
  letter-spacing: -0.3px;
}
.ProductsItemsHeader{
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 0 40px;
}
.ProductsItemsHeaderTitle{
  flex: 1;
  margin-right: 15px;
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: -0.5px;
}
.ProductsItemsHeaderSubItems{
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: -5px;
  margin-bottom: 5px;
}
.ProductsItemsHeaderSubItem{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 26px;
  padding: 2px;
  border-radius: 13px;
  color: #fff;
  background: #2b2b2b;
}
.ProductsItemsHeaderSubItemText{
  flex: 1;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  padding: 0 0px 2px 12px;
}
.ProductsItemsHeaderSubItemRemove{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 22px;
  height: 22px;
  border-radius: 11px;
}
.ProductsSideHighlightImage{
  width: 135px;
  margin-top: -100px;
  margin-bottom: -20px;
  margin-right: -35px;
}
.ProductsSideHighlightImage>img{
  width: calc(100%);
}
.ProductsSideHighlight{
  display: flex;
  align-items: flex-end;
  background: #e0197d;
  color: #fff;
  padding: 20px 25px;
  border-radius: 5px;
  margin-top: 30px;
  margin-right: -10px;
}
.ProductsSideHighlightText{
  flex: 1;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -1px;
}
.ProductsPaginationWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  padding-top: 25px;
  border-top: solid 1px rgba(0,0,0,.1);
}
.ProductsPaginationWrapper.Normal{
  padding: 10px 10px;
}
.ProductsPagination{
  display: flex;
  align-items: center;
}
.ProductsPaginationArrow{
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 15px;
  border-radius: 5px;
  background: rgba(0,0,0,.1);
  color: #3b3b3b;
  cursor: pointer;
}
.ProductsPaginationArrow:hover{
  background: rgba(0,0,0,.05);
}
.ProductsPaginationArrow.Disabled{
  opacity: .5;
  cursor: not-allowed;
  background: rgba(0,0,0,.1) !important;
}
.ProductsPaginationArrow.Left{
  padding-right: 20px;
  margin-right: 10px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}
.ProductsPaginationArrow.Right{
  padding-left: 20px;
  margin-left: 10px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.ProductsPaginationArrow.Left .ProductsPaginationArrowText{
  margin-left: 10px;
}
.ProductsPaginationArrow.Right .ProductsPaginationArrowText{
  margin-right: 10px;
}
.ProductsPaginationArrowText{
}

.ProductsPaginationItem{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 5px;
}
.ProductsPaginationItem.Selected{
  background: #e0197d;
  color: #fff;
}
.ProductsPaginationDots{
  margin: 0 10px;
}
.ProductsSideItemsWrapper{
  flex-direction: column;
}
