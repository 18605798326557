.Blogs{
  min-height: 600px;
}
.BlogsHeaderWrapper{
}
.BlogsHeader{
  display: flex;
  flex-direction: row;
  padding: 25px 25px;
  padding-top: 50px;
  position: relative;

  border-radius: 0 0 5px 5px;

  background-size: cover;
  background-position: center;
}
.BlogsHeader::after{
  content: "";
  width: calc(100%);
  height: calc(100%);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(to top right, rgba(248,241,241,1), rgba(248,241,241, 1), rgba(248,241,241,.2));
}
.BlogsHeaderImageFloatWrapper{
  position: relative;
  z-index: 5;
  perspective: 1000px;
  perspective-origin: center right;
  padding-right: 25px;
}
.BlogsHeaderImageFloat{
  display: block;
  width: 500px;
  height: 350px;
  border-radius: 25px;

  transition: all 1.5s ease-in-out;
  box-shadow: 0 75px 75px -50px rgba(0,0,0,.2);
  transform: rotateX(5deg) rotateY(10deg) rotateZ(-5deg) translateX(-75px) scale(1.2);
  position: relative;
  z-index: 5;
}
.BlogsHeaderImageFloatImage{
  display: block;
  width: calc(100%);
  height: calc(100%);
  background-color: rgba(255,255,255,.5);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 4;
  border-radius: 25px;
  transition: all 1.5s ease-in-out;
}
.BlogsHeaderImageFloat::before{
  content: "";
  display: block;
  border-radius: 25px;
  background-size: cover;
  background-position: center;

  position: absolute;
  top: 22px;
  left: -50px;
  width:  calc(100% + 50px);
  height: calc(100%);
  background: rgba(0,0,0,.2);
  filter: blur(30px);

  transform: skewY(-5deg);

  transition: all 1.5s ease-in-out;
  z-index: 4;
}
.BlogsHeader:hover .BlogsHeaderImageFloat{
  border-radius: 5px;
  box-shadow: 0 20px 30px -20px rgba(0,0,0,.7);
  transform: rotateX(0) rotateY(0) rotateZ(0) translateX(-25px) translateY(20px) scale(1.1);
}
.BlogsHeader:hover .BlogsHeaderImageFloatImage{
  border-radius: 5px;
}
.BlogsHeader:hover .BlogsHeaderImageFloat::before{
  border-radius: 5px;
  width: calc(100%);
  left: 0;
  top: 0;
  background: rgba(0,0,0,0);
  filter: blur(0);

  transform: skewY(0);
}
.BlogsHeaderDetails{
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 25px;
  padding: 25px 10px;
  margin-right: 170px;
  position: relative;
  z-index: 4;
}
.BlogsHeaderDetailsTitle{
  font-weight: bold;
  font-size: 36px;
  color: #1b1b1b;
  letter-spacing: -0.2px;
  max-width: 600px;
  line-height: 35px;
  margin-left: -5px;
}
.BlogsHeaderDetailsDate{
  margin-top: 8px;
  color: #e0197d;
  font-weight: bold;
  font-size: 15px;
}
.BlogsHeaderDetailsText{
  flex: 1;
  margin-top: 8px;
  color: #3b3b3b;
  max-width: 500px;
  font-size: 15px;
}
.BlogsHeaderOptions{
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.BlogsHeaderOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
  font-size: 15px;
}
.BlogsHeaderImage{
  width: 650px;
  height: 350px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #efefef;
  background-size: cover;
  background-position: center;
}
.BlogsItems{
  display: flex;
  padding: 25px 0;
  flex-wrap: wrap;
}
.BlogsItem{
  display: inline-block;
  vertical-align: bottom;
  color: inherit;

  width: calc(100%);
  max-width: 260px;

  margin: 5px;
  padding: 7.5px;
  background: rgba(255,255,255,.6);

  overflow: hidden;
  position: relative;
  border-radius: 5px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: all 150ms ease;
  -moz-transition: all 150ms ease;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;

  cursor: pointer;
}
.BlogsItem:hover .BlogsItemImage{
  transform: translateY(-5px) scale(1.05);
}
.BlogsItem:active{
  transform: scale(0.98);
  box-shadow: 0 5px 40px rgba(0,0,0,.1);
}
.BlogsItem:hover{
  background: rgba(255,255,255,1);
}
.BlogsItemImage{
  width: calc(100%);
  height: 160px;
  border-radius: 4px;
  background-color: rgba(0,0,0,.03);
  background-size: cover;
  background-position: center;

  transition: all 150ms ease;
  -moz-transition: all 150ms ease;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;
}
.BlogsItemDetails{
  padding: 15px;
}
.BlogsItemDetailsTitle{
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.BlogsItemDetailsDate{
  margin-top: 5px;
  opacity: .75;
  color: #e0197d;
  font-weight: bold;
  font-size: 12px;
}
.BlogsItemDetailsText{
  margin-top: 5px;
  opacity: .75;
}
