.Home{
  min-height: 1000px;
}

.HomeBannerWrapper{
  display: flex;
  width: calc(100%);
}
.HomeBanner{
  flex: 1;
  background: linear-gradient(to bottom right, rgba(0,0,0,.05), rgba(0,0,0,.08));
  /* height: 550px; */
  position: relative;
  transition: all 150ms ease-in-out;
  border-radius: 0 0 5px 5px;
}
.HomeBannerArrow{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  border: solid 10px #F8F1F1;

  position: absolute;
  top: 50%;
  margin-top: -30px;
  z-index: 25;

  cursor: pointer;
  color: #e0197d;
  font-size: 18px;
}
.HomeBannerPagination{
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 25;

  bottom: 20px;
  right: 25px;
}
.HomeBannerPagination>a{
  width: 28px;
  height: 8px;
  border-radius: 3px;
  margin-left: 5px;
  background: rgba(255,255,255,.75);
  transition: background 150ms ease-in-out;
  cursor: pointer;

}
.HomeBannerPagination>a.Selected{
  background: #e0197d !important;
}
.HomeBannerPagination>a:hover{
  background: #fff;
  transform: scale(1.1);
}
.HomeBannerArrow.Left{
  left: -30px;
}
.HomeBannerArrow.Right{
  right: -35px;
}
.HomeBannerArrow:hover{
  background: #e0197d;
  color: #fff;
}
.HomeBannerImage{
  display: flex;
  width: calc(100%);
  height: calc(100%);
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 20px;
  border-radius: 0 0 5px 5px;

  transition: all 150ms ease-in-out;
}
.HomeBannerSide{
  display: flex;
  flex-direction: column;
  width: 450px;
  margin-left: 10px;
}
.HomeBannerSideItem{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: calc(100%);
  flex: 1;
  background: linear-gradient(to bottom right, #fed0e3, #eab6cc);
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: all 150ms ease-in-out;
}
.HomeBannerSideItem:first-child{
  margin-top: 0;
  border-radius: 0 0 5px 5px;
}
.HomeBannerSide:hover .HomeBannerSideItem:not(:hover){
  opacity: .5;
  filter: brightness(.75);
}
.HomeBannerSideItem:hover .HomeBannerSideItemImage::before{
  transform: translate(50%, -50%) scale(5);
}
.HomeBannerSideItem:hover .HomeBannerSideItemImage>img{
  transform: rotate(-5deg) translateX(5px);
}
.HomeBannerSideItem.Full .HomeBannerSideItemImage{
  position: absolute;
  width: calc(100%);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding-left: 0;
}
.HomeBannerSideItem.Full .HomeBannerSideItemImage::before{
  width: calc(100%);
  height: calc(100%);
  border-radius: 0;
  transform: none;
  left: 0;
  top: 0;
  opacity: 0;
  background-image: linear-gradient(to top, rgba(0,0,0,.75), rgba(0,0,0,0));
}
.HomeBannerSideItem.Full .HomeBannerSideItemDetailsTitle{
  color: #fff;
}
.HomeBannerSideItem.Full .HomeBannerSideItemDetailsSubtitle{
  color: #fff;
  opacity: .9;
}
.HomeBannerSideItem.Full .HomeBannerSideItemImage>img{
  transform: none;
  width: calc(100%);
  height: calc(100%);
  max-width: none;
  max-height: none;
  object-fit: cover;
}

.HomeContentItemSplitterIntro.Full .HomeBannerSideItemImage{
  position: absolute;
  width: calc(100%);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding-left: 0;
  padding-top: 0;
}
.HomeContentItemSplitterIntro.Full .HomeBannerSideItemImage::before{
  width: calc(100%);
  height: calc(100%);
  border-radius: 0;
  transform: none;
  left: 0;
  top: 0;

  background-image: linear-gradient(to top, rgba(0,0,0,.75), rgba(0,0,0,0));
}
.HomeContentItemSplitterIntro.Full .HomeBannerSideItemDetailsTitle{
  color: #fff;
}
.HomeContentItemSplitterIntro.Full .HomeBannerSideItemDetailsSubtitle{
  color: #fff;
  opacity: .9;
}
.HomeContentItemSplitterIntro.Full .HomeBannerSideItemImage>img{
  transform: none !important;
  width: calc(100%);
  height: calc(100%);
  max-width: none;
  max-height: none;
  object-fit: cover;
}

.HomeBannerSideItemImage{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  padding-left: 10px;
  height: calc(100%);
  position: relative;
  z-index: 20;
}
.HomeBannerSideItemImage::before{
  display: block;
  content: "";
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: -77px;
  opacity: 0.5;
  transform: translate(0, -50%);
  transition: all 500ms ease-in-out;
}
.HomeBannerSideItemImage>img{
  max-width: 200px;
  max-height: 200px;
  transition: all 300ms ease-in-out;
  transform: rotate(-4deg);
}
.HomeBannerSideItemDetails{
  flex: 1;
  padding: 25px;
  text-align: right;
  position: relative;
  z-index: 20;
}
.HomeBannerSideItemDetailsTitle{
  font-weight: bold;
  color: #2b2b2b;
  font-size: 27px;
  line-height: 25px;
  letter-spacing: -0.2px;
  word-break: break-word;
}
.HomeBannerSideItemDetailsSubtitle{
  margin-top: 5px;
  opacity: .75;
  font-size: 16px;
}
.HomeBannerSideItemOptions{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px rgba(1,1,1,.05);
}
.HomeBannerSideItemOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-size: 15px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.HomeBannerSideItemOptionsButton:hover{
  background: #eb1e85;
}
.HomeBannerModal{
  display: block;
  position: absolute;
  bottom: 25px;
  left: -10px;
  padding: 15px 25px 20px 25px;
  background: #e0197d;
  color: #fff;
  min-width: 300px;

  transition: all 150ms ease-in-out;
  border-radius: 5px 5px 5px 2px;
}
.HomeBannerModalTitle{
  font-weight: bold;
  color: #fff;
  font-size: 35px;
  line-height: 36px;
  letter-spacing: -0.2px;
}
.HomeBannerModalText{
  color: #fff;
  font-size: 21px;
  line-height: 22px;
  margin-top: 8px;
  letter-spacing: -0.2px;
}
.HomeBannerModalOptions{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  padding-top: 20px;
  border-top: solid 1px rgba(255,255,255,.2);
}
.HomeBannerModalOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-size: 15px;
  font-weight: bold;
  background: #fff;

  color: #e0197d;
  border-radius: 16px;

  cursor: pointer;
}
.HomeBannerSale{
  display: flex;
  position: absolute;
  bottom: 75px;
  right:  50px;
  background: #e0197d;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: solid 2px #fff;

  padding-bottom: 5px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  transition: all 150ms ease-in-out;

  animation-name: animateSale;
  animation-duration: 500ms;
  animation-delay: 150ms;
  animation-fill-mode: forwards;

  transform: scale(0) rotate(-20deg);
}
@keyframes animateSale{
  0%{
    transform: scale(0) rotate(-20deg);
  }
  50%{
    transform: scale(1.05) rotate(5deg);
  }
  100%{
    transform: scale(1) rotate(-20deg);
  }
}
.HomeBanner:hover .HomeBannerSale{

}
.HomeBannerSalePrice{
  font-weight: bold;
  font-size: 55px;
  line-height: 50px;
  color: #fff;
  margin-top: -6px;
  white-space: nowrap;
  text-align: center;
}
.HomeBannerSaleText{
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  opacity: .8;
}

.HomeContentItem{
  padding: 50px 0;
}
.HomeContentItem.NoPaddedTop{
  padding-top: 0;
}
.HomeContentItemSplitter{
  display: flex;
  width: calc(100%);
  align-items: flex-end;
  margin-top: 10px;
}
.HomeContentItemSplitter:first-child{
  margin-top: 0;
}
.HomeContentItemSplitterIntro{
  display: flex;
  flex-direction: column;
  width: 375px;
  height: 375px;
  border-radius: 5px 5px 0 5px;
  margin-right: -5px;
  position: relative;
  z-index: 5;
  overflow: hidden;
  background: linear-gradient(to bottom right, #fed0e3, #eab6cc);
}
.HomeContentItemSplitterIntro:hover::after{
  transform: scale(5);
}
.HomeContentItemSplitterIntro:hover .HomeBannerSideItemImage>img{
  transform: rotate(-5deg) translateX(5px);
}
.HomeContentItemSplitterIntro .HomeBannerSideItemDetails{
  flex: none;
}
.HomeContentItemSplitterIntro .HomeBannerSideItemImage>img{
  max-height: calc(100%);
  max-width: calc(100% - 100px);
}
.HomeContentItemSplitterIntro .HomeBannerSideItemImage{
  flex: 1;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-end;
  width: calc(100%);
  padding-left: 25px;
  padding-top: 25px;
  margin-bottom: -5px;
}
.HomeContentItemSplitterIntro::after{
  display: block;
  content: "";
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: -75px;
  top: -115px;
  opacity: 0.5;
  transition: all 500ms ease-in-out;
}
.HomeContentItemSplitterIntro .HomeBannerSideItemImage::before{
  content: none;
}
.HomeContentItemSplitterIntro .HomeBannerSideItemDetails{
  text-align: left;
}
.HomeContentItemSplitterIntro .HomeBannerSideItemOptions{
  justify-content: flex-start;
}
.HomeContentItemSplitterItemsWrapper{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-x: hidden;
  height: 375px;
  position: relative;
}
.HomeContentItemSplitterItemsWrapper .HomeContentItemTitleView{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1;
  margin-top: 2px;
  padding: 10px 10px 0 20px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.HomeContentItemSplitterItemsWrapper .HomeContentItemTitle::before{
  content: none;
}
.HomeContentItemSplitterItemsView{
  display: block;
}
.HomeContentItemSplitterItems{
  display: block;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 15px;
}
.HomeContentItemSplitterItemsOptions{
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0 20px 0 35px;
}
.HomeContentItemSplitterItemsOptions .HomeContentItemTitleButton{
  width: calc(100%);
}

.HomeContentItemSplitterItems:hover .ProductItem:not(:hover){
  opacity: .75;
}
.HomeContentItemSplitterItems:active .ProductItem:not(:hover){
  transform: scale(1.01);
}
.HomeContentItemSplitterItems::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: rgba(255,255,255,.01);
}

.HomeContentItemSplitterItems::-webkit-scrollbar
{
	height: 8px;
	width: 8px;
  border-radius: 4px;
	background-color: rgba(16,16,16,.02);
}

.HomeContentItemSplitterItems::-webkit-scrollbar-thumb
{
	background-color: rgba(16,16,16,.03);
  border-radius: 4px;
}

::-webkit-selection {background: #fed0e3;}
::-moz-selection {background: #fed0e3; }
::selection {background: #fed0e3;}

.HomeContentItemTitleView{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.HomeContentItemTitle{
  flex: 1;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: -0.5px;
  padding: 0 15px;
}
.HomeContentItemTitle::before{
  content: "";
  display: inline-block;
  margin-bottom: -2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background: #3f3339;
}
.HomeContentItemTitleButton{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-size: 15px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.HomeContentItemContent{
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  gap: 10px;
}
.HomeContentItemCategory{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;

  background: rgba(255,255,255,.4);
  border-bottom: solid 5px transparent;

  transition: all 150ms ease-in-out;
}
.HomeContentItemCategory:hover{
  background: #fff;
}
.HomeContentItemCategory:hover .HomeContentItemCategoryTitle{
  color: #e0197d;
}
.HomeContentItemCategoryArrow{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: rgba(0,0,0,.02);

  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 25;

  color: #4b4b4b;
  font-size: 14px;
}
.HomeContentItemCategoryArrow.Small{
  color: #c2c2c2;
}
.HomeContentItemCategoryIcon{
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  background: rgba(0,0,0,.05);
  margin-bottom: 15px;
}
.HomeContentItemCategoryIcon>img{
  width: calc(100%);
}
.HomeContentItemCategoryTitle{
  font-weight: bold;
  font-size: 19px;
}
.HomeContentItemNewsBlock{
  margin-top: 25px;

  display: flex;
  flex-direction: row;
}
.HomeContentItemNewsHighlighted{
  background: rgba(0,0,0,.1);
  display: flex;
  flex: 1;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.HomeContentItemNewsHighlightedImage{
  width: calc(100%);
  height: calc(100%);
  background-size: cover;
  background-position: center;
}
.HomeContentItemNewsHighlightedImage::before{
  content: "";
  background: linear-gradient(to top right, rgba(0,0,0,.75), rgba(0,0,0,0));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 22;
}
.HomeContentItemNewsHighlightedModal{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 23;
  padding: 25px;
  width: 600px;
  border-radius: 5px;
  color: #fff;
}
.HomeContentItemNewsHighlightedModalTitle{
  font-weight: bold;
  font-size: 36px;
  line-height: 35px;
}
.HomeContentItemNewsHighlightedModalText{
  margin-top: 10px;
  font-size: 15px;
}
.HomeContentItemNewsHighlightedModalInfo{
  display: flex;
  margin-top: 20px;
  padding-top: 20px;
  border-top: solid 1px rgba(255,255,255,.2);
}
.HomeContentItemNewsHighlightedModalInfoItem{
  display: flex;
  align-items: center;
  flex: 1;
  font-weight: bold;
  font-size: 13px;
  line-height: 12px;
}
.HomeContentItemNewsHighlightedModalInfoItemMore{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  height: 32px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 5px 16px 16px 5px;

  cursor: pointer;
}
.HomeContentItemNewsHighlightedModalInfoItemMoreText{
  flex: 1;
  margin-top: -2px;
  font-size: 14px;
}
.HomeContentItemNewsHighlightedModalOptions{
  display: flex;
  padding-top: 20px;
  margin-top: 20px;
  border-top: solid 1px #efefef;
}
.HomeContentItemNewsHighlightedModalOptionsButton{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 5px;

  cursor: pointer;
}
.HomeContentItemNewsItemsWrapper{
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
}
.HomeContentItemNewsItems{
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 500px;
  border-radius: 5px;
  padding: 5px 0;

  overflow-x: hidden;
  overflow-y: scroll;
}
.HomeContentItemNewsOptions{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-top: solid 1px #efefef;
}
.HomeContentItemNewsOptionsButton{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-size: 15px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.HomeContentItemNewsOptionsButton:hover, .AccentHover:hover{
  background: #eb1e85;
}
.HomeContentItemNewsItemsTitle{
  padding: 15px 25px;
  background: rgba(0,0,0,.02);

  font-weight: bold;
  font-size: 19px;
  border-bottom: solid 1px #efefef;
}
.HomeContentItemNewsItem{
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 25px;
  background: #fff;
  border-radius: 5px;
  border-bottom: solid 1px #f6f6f6;
}
.HomeContentItemNewsItem:last-child{
  border-bottom: 0;
}
.HomeContentItemNewsItem:hover{
  background: #fbfbfb;
}
.HomeContentItemNewsItemImage{
  display: block;
  width: 100px;
  height: 100px;
  margin-right: 15px;
  border-radius: 5px;
  background-color: rgba(0,0,0,.1);
  background-size: cover;
  background-position: center;
}
.HomeContentItemNewsItemDetails{
  flex: 1;
}
.HomeContentItemNewsItemDetailsTitle{
  font-weight: bold;
  font-size: 17px;
}
.HomeContentItemNewsItemDetailsText{
  margin-top: 3px;
  opacity: .75;
  font-size: 15px;
}
.HomeContentItemNewsItemDetailsDate{
  margin-top: 2px;
  font-size: 13px;
  color: #e0197d;
  font-weight: bold;
}
.HomeContentItemProducts{
  display: block;
  margin-top: 25px;
}
