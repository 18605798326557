.FooterBar{
  background-color: #fffbfb;
  border-top: solid 1px #f7f1f1;
  padding: 25px 0;
  position: relative;
}
.FooterBarFitted{
  height: 25px;
  background-color: #fffbfb;
  border-top: solid 1px rgba(0,0,0,.1);
}
.FooterBar.mini{
  padding: 20px 0;
}
.FooterBar.mini .FooterSection{
  flex-direction: row;
  align-items: center;
}
.FooterBar.mini .FooterSections{
  align-items: center;
}
.FooterBar.mini .FooterCopyTitle{
  margin-top: 0;
}
.FooterBar.mini .FooterCopyWrapper{
  margin-left: 0;
}
.FooterBar.mini .FooterNewsletterWrapper{
  margin-top: 0;
}
.FooterSections{
  display: flex;
  flex-direction: row;
}
.FooterSection{
  display: flex;
  flex: 1;
  margin-right: 50px;
  flex-direction: column;
}
.FooterSectionGrid{
  flex: 2;
  display: flex;
  flex-direction: column;
}
.FooterSectionGridTwin{
  display: flex;
  flex-direction: row;
}
.FooterSectionTitle{
  font-size: 18px;
  font-weight: bold;
  color: #4c3340;
  margin-bottom: 10px;
}
.FooterSocials{
  margin-top: 30px;
  border-bottom: solid 1px #f1e6eb;
  padding-bottom: 20px;

  display: flex;
  flex-direction: row;
}
.FooterSocialItem{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  color: #7b7b7b;
  font-size: 18px;
}
.FooterSocialItem:hover{
  color: #e0197d;
}
.FooterCopyTitle{
  margin-top: 20px;
  color: #e0197d;
  font-weight: bold;
  font-size: 16px;
}
.FooterCopy{
  margin-top: 10px;
  color: #4c3340;
  font-size: 16px;
}
.FooterLogo{
  height: 50px;
  width: 50px;
}
.FooterSectionLink{
  cursor: pointer;
  padding: 2.5px 0;
  color: #5b5b5b;
  font-size: 17px;
}
.FooterSectionLink:hover{
  text-decoration: underline;
}
.FooterNewsletterWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  position: relative;
}
.FooterNewsletterPre{
  flex: 1;
  margin-right: 25px;
  text-align: right;
  margin-bottom: -1px;
}
.FooterNewsletter{
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 370px;
  overflow: hidden;

  background: #f2ecec;
  border-radius: 20px;
}
.FooterNewsletter.Unsubscribe{
  width: auto;
}
.FooterNewsletter input{
  flex: 1;
  border: 0;
  outline: 0;
  height: calc(100%);
  background: transparent;
  transition: all 300ms ease-in-out;
  padding: 0 20px;
  font-weight: 500;
  width: calc(100%);
}
.FooterNewsletter input::placeholder{
  color: #b6b6b6;
}
.FooterNewsletterGo{
  height: 40px;
  padding: 0 15px;
  color: #fff;
  background-color: #e0197d;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.FooterNewsletterGo.Minimal{
  color: #e0197d;
  background: transparent;
}
.FooterNewsletterText{
  margin-right: 10px;
  font-weight: bold;
}
.FooterCreator{
  display: flex;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
}
.FooterCreator:hover{
  text-decoration: underline;
}
.FooterCreator:hover .FooterCreaterIcon{
  animation-name: heartGrow;
}
@keyframes heartShrink{
  0%{
    scale: 1.2;
  }
  50%{
    scale: 1.4;
  }
  100%{
    scale: 1;
  }
}
@keyframes heartGrow{
  0%{
    scale: 1;
  }
  50%{
    scale: 1.4;
  }
  100%{
    scale: 1.2;
  }
}
.FooterCreaterPre{
  margin-right: 5px;
}
.FooterCreaterIcon{
  margin: 0 5px;
  animation-name: heartShrink;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.FooterCreaterPre:first-child{
  margin-right: 0;
}
.FooterCreaterName{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  color: #5c2727;
}
.FooterCreaterName b{

}
