.AboutUs{
  min-height: 600px;
  position: relative;
}
.AboutUs .FixedWidth{
  padding: 25px 30px;
  display: flex;
}
.AboutUsSide{
  width: 300px;
  margin-right: 50px;
}
.AboutUsSideTitleView{

}
.AboutUsSideTitle{
  font-size: 55px;
  line-height: 60px;
  font-weight: bold;
  letter-spacing: -1px;

  color: #2b2b2b;
  background-clip: text;
}
.AboutUsSideOptions{
  border-top: solid 1px rgba(0,0,0,.1);
  padding-top: 25px;
  margin-top: 25px;
}
.AboutUsSideOptionsTitle{
  font-weight: bold;
  margin-bottom: 10px;
}
.AboutUsSideOptionSocials{
  border-radius: 5px;
  overflow: hidden;
  margin: 0 -15px;
}
.AboutUsSideOptionSocial{
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 44px;
  cursor: pointer;
  background: rgba(255,255,255,.5);
  border-bottom: solid 1px rgba(0,0,0,0.05);
}
.AboutUsSideOption{
  display: flex;
  align-items: center;
  margin: 0 -15px;
  padding: 0 15px;
  height: 44px;
  background: #e0197d;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;

  margin-bottom: 10px;
}
.AboutUsSideOption.Close{
  background: #3f3339;
}
.AboutUsSideOptionText{
  flex: 1;
  font-weight: bold;
}
.AboutUsSideOptionStatus{
  border-radius: 10px;
  height: 20px;
  background: #fff;
  padding: 0 10px;
  font-size: 11px;
  font-weight: bold;
  color: #e0197d;
  margin-right: 10px;
  margin-left: 10px;
}

.AboutUsSideOptionSocial:hover{
  background: rgba(255,255,255,.75);
}
.AboutUsSideOptionSocialText{
  flex: 1;
}
.AboutUsSideOptionSocial.Ti .AboutUsSideOptionSocialIcon{
  font-size: 14px;
}
.AboutUsSideOptionSocial.Fb:hover .AboutUsSideOptionSocialIcon{
  color: #3b5998;
}
.AboutUsSideOptionSocial.Ig:hover .AboutUsSideOptionSocialIcon{
  color: #d62976;
}
.AboutUsSideOptionSocial.Wa:hover .AboutUsSideOptionSocialIcon{
  color: #25D366;
}
.AboutUsSideOptionSocial.Ti:hover .AboutUsSideOptionSocialIcon{
  color: #69C9D0;
}
.AboutSideFooterView{
  margin-top: 50px;
}
.AboutSideFooterText{
  font-size: 14px;
}

.AboutUsSideOptionOthers{
  border-radius: 5px;
  overflow: hidden;
  margin: 0 -15px;
  margin-top: 15px;
}
.AboutUsSideOptionOther{
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 44px;
  cursor: pointer;
  background: rgba(255,255,255,.5);
  border-bottom: solid 1px rgba(0,0,0,0.05);
}

.AboutUsSideOptionOther:hover{
  background: rgba(255,255,255,.75);
}
.AboutUsSignature{
  display: flex;
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 20px;
  color: #e0197d;
  letter-spacing: -0.2px;
  font-weight: bold;
}

.AboutUsContent{
  flex: 1;
  position: relative;
  overflow: hidden;
}
.AboutUsImage{
  display: block;
  border-radius: 5px;
  margin-bottom: 15px;
  height: 550px;
  background-size: cover;
  background-position: center;
  background-color: rgba(255,255,255,.5);

  transition: all 600ms ease-in-out;
}
.AboutUs .VacancyFormItems{
  flex: none;
}

.AboutUs .VacancyDetailsApplyView{
  rotate: 90deg;
  left: 0;
  top: 125px;
  right: auto;
  z-index: 25;
  height: auto;
  transform-origin:  top left;
}
.AboutUs.ContactMode .VacancyDetailsApplyView{
  transform: translateX(0);
  rotate: 0deg;
  scale: 1;
  top: 10px;
  border-radius: 5px;
}
.AboutUs.ContactMode .AboutUsImage{
  transform: translateX(25px);
  filter: blur(5px);
  opacity: .5;
}
.AboutUs .BlockItemWrapper{
  padding-top: 0;
  transition: all 600ms ease-in-out;
}
.AboutUs.ContactMode .BlockItemWrapper{
  transform: translateX(25px);
  opacity: .5;
  filter: blur(5px);
}
.BlockItemText{
  font-size: 15px;
}
.ContactFormDropdownOption{
  padding: 7.5px 12.5px;
}
.ContactFormDropdownOption:hover{
  cursor: pointer;
  background: rgba(255, 255, 255, .25);
}
.VacancyFormInput, .Dropdown:hover {
  cursor: pointer;
}
.VacancyFormDropdownContainer{
	background: rgba(255, 255, 255, .2);
  border-radius: 5px;
  margin-top: 5px;
}