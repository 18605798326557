* {
	outline: none;
	-webkit-box-sizing: border-box;   /* Safari 3-4, also probably droppable */
	-moz-box-sizing: border-box;   /* Firefox 1, probably can drop this */
    box-sizing:	border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0) !important;

	-webkit-appearance: none;
  line-height: 20px;
  text-decoration: none;
}

a{
  color: inherit;
}

input,textarea,select{
  border:0;
  -webkit-appearance: none;
  border-radius:0;
  font-family: 'Sofia', 'Roboto', sans-serif;
	font-size: 14px;
  color: #3f3339;
}
input:focus, textarea:focus{
  outline:none !important;
  border-color:#4b4b4b;
}

::-webkit-selection {background: #b3d4fd;}
::-moz-selection {background: #b3d4fd; color: #000;}
::selection {background: #b3d4fd;}



body {
  margin: 0;
  font-family: 'Sofia', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #3f3339;
  background-color: #F8F1F1;
}
input{

}
body, html{
  width: calc(100%);
  height: calc(100%);
}
#root {
  color: #3f3339;
}
.App{

}
.App.StopScrolling{
	overflow: hidden;
}
.AppContent{
  /* padding-top: 135px; */
  padding-top: 105px;
  height: calc(100%);
  width: calc(100%);
	transform: scale(1);

	transition: all 150ms ease-in-out;
}
.AppContent.Loading{

}

.FixedWidth{
  width: calc(100%);
  max-width: 1430px;
  padding: 0 15px;
  margin: 0 auto;
}
.GlobTexture{
  height: calc(100%);
  width: calc(100%);
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 18;
}

.modalOverlay{
	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;
	background: rgba(0,0,0,0);

	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 50;
	padding: 25px;
	opacity: 0;

	transition: all 150ms ease-in-out;
}
.modalOverlay.Front{
	z-index: 52;
}
.modalOverlay.Front .modalView{
	max-width: 450px;
}
.modalOverlay.Show.Front{
	background: rgba(214,54,54,.1);
}
.modalOverlay.Show{
	opacity: 1;
	background: rgba(0,0,0,.5);
	pointer-events: all;
}
.modalView{
	background: #fff;
	border-radius: 10px;
	width: calc(100%);
	max-width: 650px;
	opacity: 0;

	animation-name: modalOutro;
	animation-duration: 300ms;
	animation-fill-mode: forwards;
}
.modalView.Show{
	animation-name: modalIntro;
}
@keyframes modalIntro {
	0%{
		opacity: 0;
		transform: scale(0.5);
	}
	50%{
		opacity: 1;
		transform: scale(1.02);
	}
	100%{
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes modalOutro {
	0%{
		opacity: 1;
		transform: scale(1);
	}
	50%{
		transform: scale(1.02);
	}
	100%{
		opacity: 0;
		transform: scale(0.5);
	}
}
.modalHeader{
	display: flex;
	align-items: center;
	padding: 20px 30px 23px 30px;
}
.modalHeaderTitleWrapper{
	flex: 1;
	margin-right: 15px;
}
.modalHeaderTitle{
	font-weight: bold;
	font-size: 20px;
}
.modalClose{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	cursor: pointer;
	background: #efefef;
}
.modalClose:hover{
	background: #e9e9e9;
}
.modalContent{
	padding: 0 30px 20px 30px;
}
.modalContentContent.Row{
	display: flex;
	margin-bottom: 15px;
}
.modalContentContent .LoginFormOr{
	color: #fff;
}
.modalContentContent.Row .LoginFormSignupView{
	margin-top: 0;
	margin-left: 15px;
	flex: 1;
	margin-right: 0px;
}
.modalContentContentError{
	padding: 15px;
	border-radius: 5px;
	background: #f8eeee;
}
.modalContentContentTitle{
	font-weight: bold;
	font-size: 16px;
}
.modalContentContentSubtitle{
	margin-top: 5px;
}
.modalContentAnimationWrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -43px;
}
.modalContentAnimation{
	width: 225px;
	height: 200px;
	margin-top: -10px;
	margin-bottom: -25px;
}
.modalContentContent{

}
.modalContentInput{
	background: #f2f2f2;
	overflow: hidden;
	border-radius: 5px;
}
.modalContentInputPre{
	margin-bottom: 10px;
	color: #3b3b3b;
	font-weight: bold;
	padding: 0 5px;
	margin-top: 15px;
}
.modalContentInputPre:first-child{
	margin-top: 0;
}
.modalContentInput>input{
  width: calc(100%);
  background: transparent;
  height: 36px;
  padding: 0 15px;
}
.modalHeaderSubtitle{
	margin-top: 10px;
	color: #5b5b5b;
}
.modalContentOptions{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: calc(100%);
	margin-top: 20px;
	padding-top: 20px;
	border-top: solid 1px rgba(0,0,0,.05);
}
.modalContentOptions.NoBorder{
	margin-top: 0;
}
.modalContentOptions.LightBorder{
	padding-top: 15px;
	margin-top: 0;
	border-top-color: rgba(255,255,255,.1);
}
.modalContentOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e2e2e2;

  color: #3b3b3b;
  border-radius: 16px;
	margin-left: 10px;

  cursor: pointer;
}
.modalContentOptionsButton.Accept{
  background: #47bb3c;
	color: #fff;
}
.modalContentOptionsButton.Accept:hover{
	background: #53cc48;
}
.modalContentOptionsButton.Delete{
	background: #d53e3e;
	color: #fff;
}
.modalContentOptionsButton.Delete:hover{
  background: #df3c3c;
}
.modalContentOptionsButton:hover{
	background: #e9e9e9;
}
.modalContentOptionsButton.C2a{
	color: #fff;
  background: #e0197d;
}
.modalContentOptionsButton.Disabled{
	background: #5b5b5b;
}
.modalCancelViewWrapper{
	display: flex;
	flex: 1;
	justify-content: flex-end;
	margin-left: 15px;
	padding-left: 15px;
	border-left: solid 1px rgba(255,255,255,.1);
}
.modalCancelView{
	background: rgba(214, 54, 54, .3);
	color: #fff;
	padding: 15px 15px;
	border-radius: 5px;
}
.modalCancelTitle{
	font-weight: bold;
	padding: 0 5px;
	text-align: left;
	width: calc(100%);
}
.modalCancelButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: rgba(214, 54, 54, 1);
	color: #fff;
	margin-top: 15px;

  border-radius: 16px;

  cursor: pointer;
}
.modalErrorView{
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}
.modalError{
	display: flex;
	flex-direction: row;
	margin: 5px 0;
}
.modalErrorText{
	flex: 1;
	margin-top: -2px;
	margin-left: 10px;
	color: #d63636;
}
.modalResponseText{
	color: #c2c2c2;
	padding: 20px 25px;
	text-align: center;
}
.ModalSideOverlay{
	display: flex;
	width: calc(100%);
  flex-direction: row;
	justify-content: flex-end;

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;

	transition: all 300ms ease-in-out;
	pointer-events: none;
}
.ModalSideOverlayBackground{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: calc(100%);
	flex: 1;
	transition: all 300ms ease-in-out;
	padding: 50px;

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 19;
}
.ModalSideOverlayBackgroundText{
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: rgba(0,0,0,.4);
	opacity: 0;
	scale: .9;
	position: relative;
	z-index: 20;
	transition: all 150ms ease-in-out;
}
.ModalSideOverlay.Show{
	pointer-events: all;
}
.ModalSideOverlay.Show .ModalSideOverlayBackgroundText{
	opacity: 1;
	scale: 1;
}
.ModalSideOverlay.Show .ModalSideOverlayBackground{
	background: rgba(253,251,251,.9);
}
.ModalSideOverlay.Show .ModalSide{
	transform: translateX(0);
}
.DetailViewWrapper{
	width: calc(100%);
	max-width: 525px;
	padding: 5px;
	padding-right: 0;
	position: relative;
	z-index: 20;
}
.ModalSideContentButton{
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    font-weight: bold;
	background: #5b5b5b;
	color: #fff;
	margin: 7.5px 0;
    border-radius: 16px;
    cursor: pointer;
}
.ModalSideContentButton:hover{
	background: #7b7b7b;
}
.ModalSideContentButtonText{
	margin-left: 10px;
	font-weight: bold;
	line-height: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ModalSide{
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 21;
	width: calc(100%);
	height: calc(100%);
	transform: translateX(100%);
	transition: all 300ms ease-in-out;

	padding: 15px;

	background: #363234;
	border-radius: 10px 5px 5px 10px;
}
.ModalSide.Import{
	height: auto;
}

.ModalSide.Disable *{
	pointer-events: none;
}
.ModalSideContentImageWrapper.Enabled{
	pointer-events: all !important;
}
.ModalSideHeader{
	display: flex;
	align-items: center;

	padding-left: 12px;
	padding-right: 2px;
	height: 50px;
	padding-bottom: 15px;
	border-bottom: solid 1px rgba(255,255,255,.1);
}
.ModalSideHeaderClose{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	width: 32px;
	border-radius: 50%;
  margin-left: 10px;
	cursor: pointer;
	background: #efefef;
}
.ModalSideHeaderTitle{
	flex: 1;
	margin-right: 15px;
	font-weight: bold;
	font-size: 18px;
	color: #fff;
}
.ModalSideContent{
	padding: 25px 15px;
	flex: 1;
	overflow-x: hidden;
	overflow-y: scroll;
}
.ModalSideContentMessage{
	padding: 8px 12px;
	margin-top: -20px;
	border-radius: 0 0 5px 5px;
  background: #febe23;
  color: #2b2b2b;
	font-size: 12px;
	line-height: 16px;
	font-weight: bold;
	margin-bottom: 15px;
}
.ModalSideContent.ScrollBottom{
	padding: 0 15px;
	margin: 0 -20px;
}
.ModalSideContent.End{

}
.ModalSideContentCoupon{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: solid 1px rgba(255,255,255,.1);
}
.ModalSideContentCouponInputWrapper{
	display: flex;
	justify-content: center;
	margin-top: 10px;
}
.ModalSideContentCouponInput{
	display: inline-block;
	width: calc(100%);
	border-radius: 5px;
	border: solid 1px transparent;
	position: relative;
}
.ModalSideContentInputInput{

}
.ModalSideContentInputTextarea{

}
.ModalSideContentInputInput.Disabled{
	background: rgba(255,255,255,.05);
	color: #7b7b7b;
}
.ModalSideContentCouponInput.Valid{
	border-color: #4bd63e;
}
.ModalSideContentCouponInput.Invalid{
	border-color: #d53e3e;
}

.ModalSideContentInputSplitted{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}
.ModalSideContentInputSplittedPre{
	flex: 1;
}
.ModalSideContentInputSplitted .ModalSideContentInputInput{
	flex: 1;
}
.ModalSideContentInputSplittedSide{
	width: 150px;
	height: 30px;
	margin-left: 10px;
	border-radius: 5px;
}


.ModalSideContentCouponInputIcon{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;
	border-radius: 10px;
	background: #4bd63e;
	color: #363234;
	font-size: 12px;

	position: absolute;
	top: -6px;
	right: -6px;
	z-index: 10;
}
.ModalSideContentCouponPre{
	font-size: 13px;
	font-weight: bold;
	color: rgba(255,255,255,.75);
	letter-spacing: -0.2px;
}
.ModalSideContentCoupon input{
	width: calc(100%);
	max-width: 300px;
	text-align: center;
	height: 44px !important;
	margin-top: 0 !important;

	border-radius: 5px;

	font-weight: bold;
	font-size: 25px;
	color: #fff;
	letter-spacing: -0.2px;

	background: rgba(255,255,255,.2);
}
.ModalSideContentCoupon>input::placeholder{
	color: rgba(255,255,255,.3);
}
.ModalSideContentTwin{
	display: flex;
	align-items: flex-start;
	width: calc(100%);
	margin-bottom: 20px;
}
.ModalSideVacancy{
	display: flex;
	align-items: center;
	margin-top: -20px;
	margin-bottom: 15px;
}
.ModalSideVacancyText{
	color: #fff;
	padding: 5px 15px 7px 15px;
	border-radius: 0 0 5px 5px;
	font-weight: bold;
	font-size: 16px;
	background: #e0197d;
}
.ModalSideContentTwin.Single{

}
.ModalSideContentTwin.Single .ModalSideContentInput{
	margin: 0 !important;
}
.ModalSideContentInput{
	flex: 1;
}
.ModalSideContentInput.NoFlex{
	flex: none;
	padding-right: 15px;
}
.ModalSideContentItems{
	margin-top: 10px;
}
.ModalSideContentItem{
	display: flex;
	align-items: center;
	height: 30px;
	border-radius: 5px;
	padding: 0 10px;
	margin-top: 10px;
	color: #fefefe;
	background: rgba(255,255,255,.2);
	cursor: pointer;
	font-weight: bold;
}
.ModalSideContentItemText{
  flex: 1;
}
.ModalSideContentInputTextNormal{
	margin-top: 10px;
	color: #fff;
	line-height: 18px;
}
.ModalSideContentInputTextNormal.DeliveryType{
  font-weight: bold;
  font-size: 12px;
}
.ModalSideContentInputTextNormal.Padded{
	padding: 0 5px;
}
.ModalSideContentItemCircle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #47bb3c;
}
.ModalSideContentInputTextWrapper{
	display: flex;
	align-items: center;
	margin-top: 10px;
}
.ModalSideContentInputText{
	display: flex;
	align-items: center;
	/* background: #efefef; */
	padding: 0 5px;
	font-size: 16px;
	padding-bottom: 1px;
	font-weight: bold;
	border-radius: 5px;
}
.ModalSideContentInput:first-child{
	margin-right: 15px;
}
.ModalSideContentInput:last-child{
	margin-left: 15px;
}
.ModalSideContentInputTwin{
	display: flex;
	align-items: center;
	padding-left: 5px;
}
.ModalSideContentInputTwin.First{
	margin-top: 10px;
}
.ModalSideContentInputTwinPre{
	flex: 1;
	font-weight: bold;
	color: #efefef;
}
.ModalSideContentInputTwinInput{
	width: 200px !important;
}
.ModalSideContentInputPre{
	color: rgba(255,255,255,.75);
	font-size: 12px;
	font-weight: bold;
	padding: 0 5px;
}
.ModalSideContentInputOptions{
	display: flex;
	margin-top: 10px;
	align-items: center;
}
.ModalSideContentInputOptionsAdd{
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 36px;
	background: #47bb3c;
	color: #fff;
	padding-left: 15px;
	padding-right: 20px;
	border-radius: 18px;
	cursor: pointer;
}
.ModalSideContentInputOptionsAddText{
	margin-left: 8px;
	font-weight: bold;
	margin-top: -1px;
}
.ModalSide input{
	margin-top: 10px;
	width: calc(100%);
	height: 30px;
	padding: 0 10px;
	border-radius: 5px;

	color: #fff;
	letter-spacing: -0.2px;

	background: rgba(255,255,255,.2);
}
.ModalSide textarea{
	margin-top: 10px;
	width: calc(100%);
	padding: 7.5px 10px;
	border-radius: 5px;
	resize: none;

	color: #fff;
	letter-spacing: -0.2px;

	background: rgba(255,255,255,.2);
}
.ModalSide input::placeholder{
	color: rgba(255,255,255,.3);
}
.ModalSideContentImageWrapper{
	display: flex;
	align-items: center;

	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: solid 1px rgba(255,255,255,.1);
}
.ModalSideContentImageWrapper.Disable *{
	pointer-events: none !important;
}
.ModalSideContentImageDrop{
	display: flex;
	width: calc(100%);
	align-items: center;
	justify-content: center;
	background: rgba(255,255,255,.05);
	border-radius: 10px;

	height: 100px;
	border: dashed 1px rgba(255,255,255,.4);
}
.ModalSideContentImageDropText{
  color: rgba(255,255,255,.75);
  font-size: 12px;
  font-weight: bold;
  padding: 0 5px;
}
.ModalSideContentImage{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 100px;
	border-radius: 5px;
  background-size: cover;
  background-position: center;
	position: relative;
	background-color: rgba(255,255,255,.1);
}
.ModalSideContentImage.Valid{

}
.ModalSideContentImage.Valid .ModalSideContentImageOverlayCircle{
	background: #fff;
}
.ModalSideContentImage.ProductImage{
	background-color: rgba(255,255,255,.9);
	background-size: contain;
	border: solid 10px transparent;
}
.ModalSideContentImage.Circle{
	width: 100px;
	border-radius: 50%;
	overflow: hidden;
}
.ModalSideContentImage:hover .ModalSideContentImageOverlay{
	opacity: 1;
}
.ModalSideContentImage:hover .ModalSideContentImageOverlayCircle{
	scale: 1;
}
.ModalSideContentImageOverlay{
	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0,0,0,.2);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 5;
	border-radius: 10px;
	opacity: 0;
	transition: all 150ms ease-in-out;
}
.ModalSideContentImageOverlayCircle{
	display: flex;
	align-items: center;
	justify-content: center;

	scale: .9;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	background: rgba(255,255,255,.75);
	cursor: pointer;

	transition: all 150ms ease-in-out;
}
.ModalSideContentImageDetails{
	flex: 1;
	margin-left: 20px;
	padding-left: 20px;
	border-left: solid 1px rgba(255,255,255,.1);
}
.ModalSideContentImageDetailsText{
	color: rgba(255,255,255,.6);
	font-size: 13px;
	padding: 0 10px;
}
.ModalSideContentImageDetailsHint{
	color: rgba(255,255,255,.6);
	font-size: 13px;
	padding: 0 10px;
}
.ModalSideContentImageDetailsOptions{
	display: flex;
	align-items: center;
	margin-top: 15px;
}
.ModalSideContentImageDetailsOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e2e2e2;
	margin-right: 10px;

  color: #3b3b3b;
  border-radius: 16px;

  cursor: pointer;
}
.ModalSideContentImageDetailsOptionsButton:hover{
	background: #efefef;
}
.ModalSideContentImageDetailsOptionsButton.Delete{
	background: #d53e3e;
	color: #fff;
}
.ModalSideContentImageDetailsOptionsButton.Delete:hover{
  background: #df3c3c;
}
.ModalSideContentImageDetailsOptionsButton.Retry{
	background: #47bb3c;
	color: #fff;
}
.ModalSideContentImageDetailsOptionsButton.Retry:hover{
  background: #5ed053;
}
.popupOverlay{
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 50;
	padding: 15px;
	opacity: 0;

	pointer-events: none;

	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;

	transition: all 150ms ease-in-out;
}
.popupOverlay.Show{
	opacity: 1;
}
.popupOverlay::before{
	content: "";
	width: 1000px;
	height: 1000px;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 25;
	background-image: linear-gradient(to left top, rgba(0,0,0,.5), rgba(0,0,0,0), rgba(0,0,0,0));
}
.popupView{
	display: block;
	background: #fff;
	border-radius: 25px 25px 10px 25px;
	width: calc(100%);
	max-width: 300px;

	position: relative;
	z-index: 26;
	padding: 25px;
	pointer-events: all;

	transform: translateX(400px);
	transition: all 300ms ease-in-out;
}
.popupView.Show{
	transform: translateX(0);
}
.popupViewHeader{
	display: flex;
	width: calc(100%);
	height: 55px;
	margin-bottom: 15px;
	border-bottom: solid 1px #efefef;
	align-items: center;
	justify-content: center;

	padding: 20px;
	padding-top: 10px;
	overflow: hidden;

	animation-delay: 1500ms;
	animation-name: cookiesWrapperAnimation;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
}
.popupViewHeader>img{
	max-width: calc(100% - 40px);

	transform: scale(.9) translateY(200px) rotate(-120deg);
	animation-delay: 1500ms;
	animation-name: cookiesAnimation;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
}
@keyframes cookiesWrapperAnimation {
	0%{
		height: 55px;
	}
	20%{
		height: 150px;
	}


	80%{
		height: 150px;
	}
	100%{
		height: 55px;
	}
}
@keyframes cookiesAnimation {
	0%{
		transform: scale(.9) translateY(200px) rotate(-120deg);
	}
	20%{
		transform: scale(1.02) translateY(0);
	}

	50%{
		transform: scale(1);
	}

	80%{
		transform: scale(1.02) translateY(0);
	}
	100%{
		transform: scale(.9) translateY(200px)  rotate(120deg);
	}
}
.popupViewContent{

}
.popupViewContentTitle{
	font-weight: bold;
  font-size: 25px;
  letter-spacing: -0.5px;
}
.popupViewContentSubtitle{
	margin-top: 5px;
	line-height: 18px;
	color: #5b5b5b;
}
.popupViewOptions{
	display: block;
	margin-top: 15px;
	border-top: solid 1px #efefef;
	padding-top: 10px;
}
.popupViewOptionsButton{
  display: flex;
  align-items: center;
	justify-content: center;

	cursor: pointer;

  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #efefef;
	color: #3b3b3b;

  border-radius: 16px;
	margin-top: 10px;

  cursor: pointer;
}
.popupViewOptionsButton.C2a{
	background: #e0197d;
  color: #fff;
}
.modalDeliveryView{
	display: flex;
	align-items: flex-start;
	margin-top: -5px;
	margin-bottom: 25px;
}
.modalDelivery{
	display: flex;
	align-items: flex-start;
	padding: 10px 15px;
	padding-right: 15px;
	border-radius: 5px;
	color: #fff;
	background: rgba(255,255,255,.1);
}
.modalDeliveryDetails{
	margin-left: 15px;
}
.modalDeliveryText{
	font-weight: bold;
}
.modalDeliveryAddress{
	font-size: 13px;
	opacity: .8;
}
.ModalSideImageItems{
	display: flex;
	flex-wrap: wrap;
	overflow-x: scroll;
	padding-bottom: 15px;
	margin: -5px;
	margin-bottom: -25px;
}
.ModalSideImageItem{
	display: inline-block;
	vertical-align: top;
	position: relative;

	cursor: pointer;
	margin: 5px;

	overflow: hidden;
	width: 150px;
	height: 100px;
	border-radius: 5px;
	background-color: rgba(255,255,255,.3);
	background-position: center;
	background-size: cover;
}
.ModalSideImageItem::before{
	display: flex;
	align-items: center;
	justify-content: center;
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;

	background: rgba(255,255,255,.1);

	transition: all 150ms ease-in-out;
}
.ModalSideImageItem:hover::before{
	content: "";
	opacity: 1;
}

.ModalSideImageItemClose{
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 5px;
	right: 5px;
	width: 18px;
	height: 18px;
	border-radius: 5px;
	background: #d63636;
	color: #fff;
}

.ModalSideImageItem.Add{
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 25px;
	background: rgba(255,255,255,.05);
	border: 2px dashed rgba(255,255,255,.5);
}
.ModalSideImageItem.Add:hover{
	border: 2px dashed rgba(255,255,255,.7);
}
