.DALogoView{
  width: calc(100%);
  height: calc(100% + 60px);
  position: absolute;
  top: -60px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
}
.DALogoCanvas{
  width: calc(100%);
  height: calc(100%);
}
