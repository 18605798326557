@font-face {
  font-family: 'Roboto';
  font-weight: bold;
	src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
	src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 100;
	src: url('./fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Sofia';
  font-weight: normal;
	src: url('./fonts/Sofia-Regular.ttf');
}

@font-face {
  font-family: 'Sofia';
  font-weight: bold;
	src: url('./fonts/Sofia-Bold.ttf');
}
