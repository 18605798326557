.Vacancy{
  min-height: 400px;
  overflow: hidden;
  position: relative;
}
.VacanciesHeader.Mini{
  height: 25px;
  padding: 0;
}
.VacancyDetailSplitter{
  display: flex;
  width: calc(100%);
  margin-top: 25px;
  margin-bottom: 25px;
}
.VacancyDetailSplitterContent{
  flex: 1;
  white-space: normal;
  overflow: hidden;
  position: relative;
}
.VacancyDetailsSideWrapper{
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: calc(100%);
  max-width: 350px;

  transition: all 300ms ease-in-out;
}
.VacancyDetailsSide{
  display: block;
  width: calc(100%);

  background: #fff;
  border-radius: 10px;
}
.VacancyDetailsContent{
  display: block;

  padding: 15px;
  padding-right: 25px;
  white-space: wrap;
  min-height: 700px;

  border-right: solid 1px rgba(0,0,0,.1);
  transition: all 300ms ease-in-out;

  border-radius: 5px 5px 5px 5px;
}
.VacancyDetailsSideTitle{
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -1px;

  padding: 18px 25px 20px 25px;
  border-bottom: solid 1px rgba(0,0,0,.05);
}
.VacancyDetailsSideText{
  line-height: 22px;
  padding: 15px 25px;
}
.VacancyDetailsSideOptions{
  display: flex;
  align-items: center;
  padding: 15px 25px;
  border-top: solid 1px rgba(0,0,0,.05);
}
.VacancyDetailsSideOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.VacancyDetailsContentTitle{
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: -1px;

  padding: 0 0 20px 0;
  border-bottom: solid 1px rgba(0,0,0,.05);
}
.VacancyDetailsContentText{
  margin-top: 23px;
  line-height: 22px;
}
.VacancyDetailsContentItem{
  margin-top: 20px;
}
.VacancyDetailsContentItem.Rowed{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.VacancyDetailsContentItem.Bold  .VacancyDetailsContentItemText{
  font-weight: bold;
  color: #e0197d;
}
.VacancyDetailsContentItem.Rowed .VacancyDetailsContentItemTitle{
  margin-right: 15px;
  font-size: 15px;
  margin-bottom: 0;
}
.VacancyDetailsContentItem.Rowed .VacancyDetailsContentItemText{
  line-height: normal;
}
.VacancyDetailsContentItemTitle{
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}
.VacancyDetailsContentItemText{
  line-height: 22px;
}
.VacancyDetailsContentItems{
  display: block;
  margin-top: 10px;
}
.VacancyDetailsContentReqItem{
  display: block;
  margin-bottom: 6px;
  position: relative;
}
.VacancyDetailsContentReqItem::before{
  display: inline-block;
  vertical-align: top;
  content: "";
  margin-top: 7px;
  width: 6px;
  height: 6px;
  background: #3f3339;
  border-radius: 50%;
  margin-right: 8px;
}
.VacancyDetailsApplyView{
  display: flex;
  flex-direction: column;
  white-space: normal;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: calc(100%);
  overflow: hidden;
  padding: 5px;

  background: #3f3339;
  color: #fff;
  width: calc(100%);
  max-width: 500px;
  transition: all 300ms ease-in-out;
  height: calc(100%);
  rotate: -90deg;
  transform: translateX(100%);
  scale: 0;
  transform-origin:  top right;
}
.VacancyDetailsApplyHeader{
  display: flex;
  align-items: center;
  padding: 0 20px;
  padding-top: 20px;
  padding-bottom: 25px;
  margin-bottom: 5px;
  border-bottom: solid 1px rgba(255,255,255,.1);
}
.VacancyDetailsApplyHeaderTitle{
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -1px;
  margin-left: 15px;
  margin-top: -1px;
}

.Vacancy.ApplyMode .VacancyDetailsContent{
  transform: translateX(-500px);
  opacity: .5;
  filter: blur(5px);
}
.Vacancy.ApplyMode .VacancyDetailsApplyView{
  transform: translateX(0);
  rotate: 0deg;
  scale: 1;
  border-radius: 5px;
}
.Vacancy.ApplyMode .VacancyDetailsSideWrapper{
}


.VacancyFormPre{
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 12px;
  padding: 0 10px;
}
.VacancyFormItems{
  display: block;
  flex: 1;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.VacancyFormOptions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 25px 15px 25px;
  border-top: solid 1px rgba(255,255,255,.1);
}
.VacancyFormOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.VacancyFormOptionsButton.Back{
  margin-left: 10px;
  background: #e2e2e2;
  color: #3b3b3b;
}
.VacancyFormItem{
  display: block;
  margin-bottom: 20px;
}
.VacancyFormInput{
	width: calc(100%);
	padding: 7.5px 12.5px;
	border-radius: 5px;

	font-weight: bold;
	color: #fff;
	letter-spacing: -0.2px;

	background: rgba(255,255,255,.2);
}
.VacancyFormTextarea{
	width: calc(100%);
	padding: 7.5px 10px;
	border-radius: 5px;

	font-weight: bold;
	color: #fff;
	letter-spacing: -0.2px;
  resize: none;

	background: rgba(255,255,255,.2);
}
.VacancyFormInput input::placeholder{
	color: rgba(255,255,255,.3);
}
.VacancyFormInputView{
  display: flex;
}
.VacancyFormItemTwin{
  display: flex;
  align-items: center;
}
.VacancyFormItemTwin .VacancyFormItem{
  flex: 1;
}
.VacancyFormItemTwin .VacancyFormItem:first-child{
  margin-right: 15px;
}
.VacancyFormItemFile{
  display: flex;
  align-items: center;
  background: rgba(255,255,255,.25);
  padding: 12px 12px;
  border-radius: 5px;
}
.VacancyFormItemFileIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: rgba(255,255,255,.25);
}
.VacancyFormItemFileIcon.Selected{
  background: #009f2e;
}
.VacancyFormItemFileTitle{
  flex: 1;
  margin-left: 12px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  word-break: break-all;
}
.VacancyFormItemFileButton{
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 28px;
  line-height: 32px;
  font-weight: bold;
  background: rgba(255,255,255,.3);

  color: #fff;
  border-radius: 5px;

  cursor: pointer;
}
