.Cart{
  height: calc(100vh - 161px - 55px);
}
.Cart .FixedWidth{
  height: calc(100%);
}
.Cart.Placed .CartContent{
  display: none;
}
.CartWrapper{
  display: flex;
  width: calc(100%);
  height: calc(100%);
  overflow: hidden;
}
.CartItemsEmpty{
  display: flex;
  padding: 20px 25px;
}
.CartItemsEmptyText{
  font-size: 14px;
  margin-left: 10px;
}
.CartContent{
  flex: 1;
  padding: 25px 0;
  margin-top: 5px;
  padding-right: 25px;
  overflow-y: scroll !important;
}
.CartContentItems{
  margin-top: 10px;
}
.CartItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: solid 1px rgba(0,0,0,.05);
}
.CartItem.Error{
	background: rgba(214, 54, 54, .075);
  border-bottom: none;
}
.CartItem:nth-child(odd){
}
.CartItemImageWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 5px;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  margin-right: 20px;
}
.CartItemDetailsWrapper{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.CartItemRestWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CartItemRestText{

}
.CartItemRestText.Bold{
  font-weight: bold;
}
.CartContent .CartItemDetails{
  padding: 5px 0;
}
.CartItemDetails{
  flex: 1;
  padding: 10px 0;
  padding-right: 15px;
  margin-right: 15px;
  border-right: solid 1px rgba(0,0,0,.05);
}
.CartItemDetailsTitle{
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
}
.CartItemDetailsTitle:hover{
  opacity: 1;
  text-decoration: underline;
}
.CartItemDetailsPrice{
  margin-top: 5px;
  color: #e0197d;
  font-weight: bold;
}
.CartItemDetailsError{
  font-size: 13px;
  color: #d63636;
}
.CartItemImage{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100%);
  width: calc(100%);
}
.CartItemImage>img{
  max-width: calc(100%);
  max-height: calc(100%);
  transition: all 300ms ease-in-out;
}

.CartItemFlipper{
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.1);
  border-radius: 18px;
  height: 30px;
  overflow: hidden;

  -webkit-user-select: none;
  user-select: none;
}
.CartItemFlipper.Multiple{
  background: #e0197d;
  color: #fff;
}
.CartItemFlipperItem{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: calc(100%);
  cursor: pointer;
}
.CartItemFlipperItem:first-child{
  padding-left: 5px;
}
.CartItemFlipperItem:last-child{
  padding-right: 5px;
}
.CartItemFlipperItem:hover{
  background: rgba(255,255,255,.2);
}
.CartItemFlipperText{
  padding: 0 8px;
  width: 30px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.CartItemPrice{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #e0197d;
  font-weight: bold;
  padding-left: 15px;

  margin-left: 15px;
  border-left: solid 1px rgba(0,0,0,.1);

  padding-right: 15px;
  border-right: solid 1px rgba(0,0,0,.1);

  width: 150px;
}
.CartItemOptions{
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.CartItemOptionsButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(255,255,255,.7);
  color: #d53e3e;
  cursor: pointer;
  border-radius: 18px;
  font-size: 14px;

  transition: all 150ms ease-in-out;
}
.CartItemOptionsButton.Add{
  width: 30px;
  height: 30px;
  background: rgba(71, 187, 60, .85);
  color: #fff;
  font-size: 16px;
}
.CartItemOptionsButton.Add:hover{
  background: rgba(71, 187, 60, .75);
}
.CartItemOptionsButtonText{
  margin-right: 10px;
  margin-top: -1px;
  font-weight: bold;
}
.CartItemOptionsButton:hover{
  background: #fff;
}
.CartItemOptionsButton.Confirm{
  width: auto;
  padding-right: 12px;
  padding-left: 18px;
  background: #d53e3e;
  color: #fff;
}
.CartItemOptionsButton.Confirm:hover{
  background: #e43d3d;
}


.CartContentTitle{
  font-weight: bold;
  font-size: 22px;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
  padding: 5px 10px;
}
.CartPayment .CartContentTitle{
  padding: 5px 35px;
}
.CartPayment{
  display: flex;
  flex-direction: column;
  width: 500px;
  background: #3f3339;
  padding-bottom: 25px;
  padding-top: 30px;
  overflow-y: scroll !important;

  position: relative;
}
.CartEmptyView{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  background: #3f3339;
  padding-bottom: 25px;
  padding-top: 30px;
  overflow-y: scroll !important;

  position: relative;
}
.CartPlaced{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100%);
  background: #e0197d;
  padding-bottom: 25px;
  padding-top: 30px;
  margin: 10px 0;
  border-radius: 10px;

  position: relative;
}
.CartPayment .CartContentTitle{
  color: #fff;
}
.CartPlacedAnimationView{
  display: flex;
  width: calc(100%);
  align-items: center;
  justify-content: center;
}
.CartPlacedAnimation{
  width: 150px;
  height: 150px;
}
.CartPaymentContent{
  flex: 1;
  padding: 0 25px;
  padding-bottom: 20px;
  overflow-y: scroll !important;
}
.CartPaymentContentTitle{
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  letter-spacing: -0.3px;
  margin-bottom: 15px;
  padding: 0 10px;
}
.CartPaymentOptions{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  padding-top: 20px;
  border-top: solid 1px rgba(255,255,255,.2);
}
.CartPaymentOptionsHint{
  margin-bottom: 20px;
  color: #efefef;
}
.CartPaymentOptionsButton{
  display: flex;
  width: calc(100%);
  align-items: center;
  height: 36px;
  padding: 0 20px;
  background: #e0197d;
  color: #fff;
  cursor: pointer;
  border-radius: 16px;
}
.CartPaymentOptionsButtonText{
  flex: 1;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
  text-overflow: ellipsis;
}
.CartPaymentSelector{
  margin-top: 15px;
  margin-bottom: 25px;

  -webkit-user-select: none;
  user-select: none;
}
.CartPaymentSelector.Rowed{
  display: flex;
  flex-direction: row;
}
.CartPaymentSelector.Rowed .CartPaymentSelectorItem{
  flex: 1;
  margin-left: 10px;
}
.CartPaymentSelector.Rowed .CartPaymentSelectorItem:first-child{
  margin-left: 0;
}
.CartPaymentSelectorItemAddAddress{
  display: flex;
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 5px;
  color: #fff;
  cursor: pointer;
  background: rgba(255,255,255,.28);
}
.CartPaymentSelectorItemAddAddress:hover{
  background: rgba(255,255,255,.3);
}
.CartPaymentSelectorItemAddAddressText{
  flex: 1;
  margin-top: -1px;
  color: #fff;
  font-weight: bold;
}
.CartPaymentSelectorItem{
  display: block;
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 5px;
  color: #fff;
  opacity: .8;
  cursor: pointer;
  background: rgba(255,255,255,.3);
}
.CartPaymentSelectorItem:hover{
  opacity: .9;
}
.CartPaymentSelectorItem.Selected{
  opacity: 1;
  color: #e0197d;
  background: rgba(255,255,255,.9);
  border-left: solid 5px #e0197d;
}
.CartPaymentSelectorItem.Selected .CartPaymentSelectorItemDescr{
  color: #3b3b3b;
}
.CartPaymentSelectorItemHeader{
  display: flex;
  align-items: center;
}
.CartPaymentSelectorItemHeaderTitle{
  flex: 1;
  margin-left: 10px;
  font-size: 15px;
  font-weight: bold;
}
.CartPaymentSelectorItemDelete{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: rgba(0,0,0,.2);
  color: #fff;
}
.CartPaymentSelectorItemDescr{
  margin-top: 5px;
  font-size: 14px;
  opacity: .8;
}
.CartTotalsWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  margin-top: 50px;
}
.CartTotals{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  background: #fff;
  border-radius: 5px;
}
.CartTotalsDivider{
  background: rgba(0,0,0,.1);
  height: 1px;
  width: calc(100%);
  margin-bottom: 15px;
}
.CartTotalsItem{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 15px;
  width: calc(100%);
}
.CartTotalsItem.Discount{
  padding: 5px 10px 7px 10px;
  margin-bottom: 10px;
  margin-top: -5px;
  margin-right: -15px;
  width: calc(100% + 20px);
  background: #f6ecf1;
  border-radius: 5px;
}
.CartTotalsItem:last-child{
  margin-bottom: 0;
}
.CartTotalsItemPre{
  flex: 1;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  margin-right: 25px;
}
.CartTotalsItemAmountView{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 200px;
}
.CartTotalsItemAmountView .CartTotalsItemAmount{
  width: auto;
}
.CartTotalsItemAmount{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 200px;
  font-weight: bold;
}
.CartTotalsItemAmountButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255,255,255,.75);
  color: #3b3b3b;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: -2px;
}
.CartTotalsItemAmountButton:hover{
  background: #fff;
}
.CartTotalsItemAmount b{
  font-size: 21px;
  line-height: 22px;
  margin-left: 5px;
}
.CartTotalsItemAmount.Highlight{
  color: #e0197d;
}
.CartTotalsItemAmount.Free b{
  font-size: 16px;
  color: #e0197d;
}
.CartCouponViewWrapper{
  display: flex;
  background: #fff;
  margin-top: 25px;
  padding: 15px 20px;
  border-radius: 5px;
  width: calc(100%);
}
.CartCouponView{
  display: block;
  width: 350px;
  padding-right: 25px;
  margin-right: 25px;
  border-right: solid 1px rgba(0,0,0,.05);
  position: relative;
}
.CartCouponTitle{
  padding: 0 5px;
  font-size:15px;
}
.CartCoupon{
  display: flex;
  margin-top: 15px;
  align-items: center;
  background: #efefef;
  border-radius: 18px;

  position: relative;
}
.CartCouponInput{
  flex: 1;
  width: 192px;
  background: transparent;
  height: 36px;
  padding: 0 15px;
  font-weight: bold;
}
.CartCouponButton{
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 20px;
  background: #e0197d;
  color: #fff;
  cursor: pointer;
  border-radius: 5px 16px 16px 5px;
}
.CartCouponButtonText{
  line-height: 32px;
}
.CartStoresView{
  display: block;

  margin: 0 -15px;
  padding: 0 15px;

  padding-bottom: 15px;

  margin-top: 15px;

  white-space: nowrap;
  overflow-x: scroll;
}
.CartStoreItem{
  display: inline-block;
  vertical-align: top;
  width: 150px;
  margin-right: 10px;
  white-space: normal;

  position: relative;
  cursor: pointer;

  color: #fff;
  padding: 5px;
  border-radius: 5px;
  background: rgba(255,255,255,.25);
}
.CartStoreItem.Selected{
  background: rgba(255,255,255,.75);
  color: #3b3b3b;
}
.CartStoreItem.Selected::before{
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 5px 0px 8px 0px;
  background: #e0197d;
  position: absolute;
  top: 0;
  left: 0;
}
.CartStoreItem.Selected .CartStoreDetailsTitle{
  color: #e0197d;
}
.CartStoreImage{
  display: block;
  height: 75px;
  border-radius: 5px;

  background-size: cover;
  background-position: center;
  background-color: rgba(255,255,255,.2);
}
.CartStoreDetails{
  margin-top: 5px;
  padding: 5px;
}
.CartStoreDetailsTitle{
  font-weight: bold;
}
.CartStoreDetailsAddress{
  font-size: 13px;
  line-height: 16px;
}
.CartAddressWrapper{
  margin-top: 15px;
  padding: 7.5px;
  border-radius: 10px;
  background: #4a5549;
}
.CartAddressHeader{
  display: flex;
  align-items: center;
  margin-top: -7.5px;
  width: calc(100% + 15px);
  margin-left: -7.5px;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  letter-spacing: -0.3px;
  margin-bottom: 7.5px;
  padding: 10px 15px;
  background: rgba(0,0,0,.1);
  border-radius: 10px 10px 0 0;
}
.CartAddress{
  display: flex;
  align-items: center;
  margin-bottom: 7.5px;
}
.CartAddressArea{
  margin-left: 10px;
  width: 200px;
}
.CartAddressArea .DropDownSelected{
  margin-top: 0;
}
.CartAddressInput{
  flex: 1;
  background: transparent;
  padding: 5px 10px;
  color: #fff;
}
.CartAddressInput::placeholder{
  color: rgba(255,255,255,.5);
}
.CartInformationItems{
  background: rgba(255,255,255,.2);
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px 10px;
}
.cartInformationItem{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.cartInformationItem:first-child{
  margin-top: 0;
}
.cartInformationItemPre{
  width: 150px;
  margin-right: 10px;
	color: #efefef;
	font-weight: bold;
	padding: 0 5px;
}
.cartInformationItemInput{
  flex: 1;
  background: rgba(0,0,0,.2);
  height: 32px;
  border-radius: 5px;
  padding: 0 13px;
  color: #fff;
}

.CartPlacedModal{
  text-align: center;
  padding: 0 25px;
  color: #fff;
}
.CartPlacedModalTitle{
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: -0.2px;
  word-break: break-word;
  margin-bottom: 10px;
}
.CartPlacedModalSubtitle{
}
.CartPlacedModalOptions{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 1px rgba(255,255,255,.25);
}
.CartPlacedModalOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: rgba(255,255,255,.25);
  margin-left: 10px;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.CartPlacedModalOptionsButton:first-child{
  margin-left: 0;
}
.CartPlacedModalOptionsButton:hover{
  background: rgba(255,255,255,.3);
}
.CartPlacedModalOptionsButton.C2a{
  background: rgba(255,255,255,.4);
}
.CartPlacedModalOptionsButton.C2a:hover{
  background: rgba(255,255,255,.45);
}

.CartItemSaveOptions{
  display: flex;
  align-items: center;
  width: calc(100%);
  margin-top: 5px;
}
.CartItemSaveOptionsButton{
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}
.CartLaterView{
  margin-top: 25px;
}
.CartLaterTitle{
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 15px;
  padding: 5px 10px;
}
.CartTotalsLimit{
  width: calc(100%);
  padding: 8px 15px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  background: #d63636;
}