.NotificationsWrapper{
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 35;
  width: 100vw;
  max-width: 1500px;
  height: 100vh;
  transform: translateX(-50%);
  padding-top: 117px;
  pointer-events: none;
  padding-left: 10px;
}
.NotificationsItemWrapper{
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.NotificationsItem{
  display: flex;
  max-width: 500px;
  flex-direction: row;
  padding: 20px 20px;
  background: #fff;
  border-radius: 5px;
}
.NotificationsItemDot{
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #e0197d;
  margin-top: 5px;
}
.NotificationsItemDetails{
  flex: 1;
  margin-left: 15px;
}
.NotificationsItemDetailsText{
  font-weight: 500;
}
.NotificationWrapper{
  display: flex;
  width: calc(100%);
  max-width: 750px;
  background: #fdfdfd;
  border: solid 1px rgba(0,0,0,.025);
  border-radius: 5px;
  margin: 5px 5px;
  position: relative;
  padding: 15px 25px;

  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}
.NotificationWrapper:hover{
  background: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,.01);
}
.NotificationWrapper:first-child{
  margin-top: 30px;
}
.NotificationNew{
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #e0197d;
  margin-top: 6px;
  margin-right: 8px;
}
.NotificationDetails{
  flex: 1;
  /* margin-left: 15px; */
}
.NotificationIcon{
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 25px;
  border-left: solid 1px rgba(0,0,0,.01);
}
.NotificationDetailsNotification{
  font-weight: bold;
  font-size: 16px;
}
.NotificationDetailsNotification.Unseen{
  color: #e0197d;
}
.NotificationDetailsText{
  font-weight: bold;
  color: #e0197d;
  margin-top: 5px;
}
.NotificationDetailsDate{
  margin-top: 5px;
  color: #7b7b7b;
}
