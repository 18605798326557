.Blank{
  min-height: 600px;
}
.HomeBannerImageView{
  display: flex;
  width: calc(100%);
  height: calc(100%);
  background-size: cover;
  background-position: center;
  opacity: 0;

  transition: all 500ms ease-in-out;
  animation-name: animateBanner;
  animation-duration: 500ms;
  animation-fill-mode: forwards;

  position: relative;
  border-radius: 0 0 5px 5px;
}
@keyframes animateBanner{
  0%{
    opacity: 0;
    transform: scale(1.02);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}
.HomeBannerImageView.Transitioning .HomeBannerImage{
  transform: scale(1.02);
  opacity: 0;
}
.HomeBannerImageView.Transitioning .HomeBannerModal{
  filter: blur(5px);
}
.HomeBannerImageView.Transitioning .HomeBannerSale{

}
.HomeBannerSale.Large{
  width: 180px;
  height: 180px;
}
.HomeBannerSale.Large .HomeBannerSalePrice{
  font-size: 40px;
}
