.ProductItem{
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
  color: inherit;

  margin: 5px;

  background-color: #fcfcfc;

  border-radius: 10px;
  overflow: visible;
  position: relative;
  z-index: 5;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: all 150ms ease;
  -moz-transition: all 150ms ease;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;

  cursor: pointer;

}
.ProductItemContainer{
  display: inline-block;
  vertical-align: top;
  width: 199px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 15;
}
.ProductItem:hover{
  background: rgba(255,255,255,.75);
}
.ProductItem:hover .ProductItemImage > img{
  transform: translateY(-5px) rotate(-5deg);
}
.ProductItem:active{
  transform: scale(0.98);
  box-shadow: 0 5px 40px rgba(0,0,0,.05);
}
.ProductItemImage{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  height: 150px;
  width: 100%;
  position: relative;
  background-color: rgba(0,0,0,.01);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ProductItemImage > img{
  max-width: 100%;
  max-height: 100%;

  transition: all 150ms ease;
  -moz-transition: all 150ms ease;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;
}
.ProductItemCategory{
  display: inline-block;
  position: absolute;
  bottom: -11px;
  left: 20px;

  height: 22px;
  line-height: 22px;

  font-size: 13px;
  font-weight: bold;

  color: #fff;
  padding: 0 10px;

  border-radius: 10px;
}
.ProductItemDetails{
  display: flex;
  flex: 1;
  box-shadow: 0 -5px 50px rgba(0,0,0,0.04);

  padding: 20px;
  white-space: normal;

  flex-direction: column;
}
.ProductItemTitle{
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ProductItemSubtitle{
  margin-top: 3px;
  font-size: 16px;
  line-height: 16px;
  color: #7b7b7b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ProductItemPriceView{
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 1px #f6f6f6;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.ProductItemPricePre{
  font-size: 15px;
  font-weight: bold;
  margin-right: 10px;
  padding-right: 10px;
  color: #9b9b9b;

  border-right: solid 1px #f2f2f2;
}
.ProductItemPrice{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.ProductItemPriceCurr{
  font-size: 12px;
  line-height: 13px;
  font-weight: bold;
  color: #e0197d;

  margin-right: 5px;
}
.ProductItemPriceAmount{
  font-size: 26px;
  line-height: 22px;
  font-weight: bold;
  color: #e0197d;
}
.ProductItemPriceSubamount{
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #e0197d;

  margin-left: 2px;
}
.ProductItemOptionsWrapper{
  display: inline-block;
  vertical-align: top;
  height: 283px;
  overflow: hidden;
  width: 0;
  border-left: solid 0px transparent;

  background: #fcfcfc;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;
  transition: all 150ms ease;
  -moz-transition: all 150ms ease;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;
}
.ProductItemOptions{
  display: flex;
  flex-direction: column;
  width: 210px;
  height: calc(100%);
}
.ProductItemOptionsContent{
  padding: 15px;
  flex: 1;
  white-space: normal;
  overflow-x: hidden;
  overflow-y: scroll;
}
.ProductItemOptionsOptions{
  display: flex;
  align-items: center;
  height: 52px;
  width: calc(100%);
  padding: 0 15px;

  border-top: solid 1px rgba(1,1,1,.05);
}
.ProductItemOptionsOptionsButton{
  display: flex;
  width: calc(100%);
  align-items: center;
  justify-content: center;
  height: 32px;
  line-height: 32px;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;
  padding: 0 5px;
  font-size: 12px;
}
.ProductItemOptionsOptionsButtonText{
  font-weight: bold;
  margin-right: 5px;
  font-size: 14px;
}
.ProductItem:nth-child(5n+1).Expand .ProductItemOptionsWrapper{
  transform: translateX(-220px);
  border-radius: 10px 0 0 10px;
}
.ProductItem:nth-child(5n+1).Expand .ProductItemContainer{
  border-radius: 0 10px 10px 0;
}
.ProductItem.Expand .ProductItemOptionsWrapper{
  width: 220px;
  border-left: solid 1px rgba(0,0,0,.05);
  transform: translateX(200px);
  z-index: 11;
  border-radius: 0 10px 10px 0;
}
.ProductItem.Expand .ProductItemContainer{
  border-radius: 10px 0 0 10px;
}
.ProductItem.Expand{
  z-index: 10;
}
.ProductItemOptionsTitle{
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: #3b3b3b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ProductItemOptionsText{
  margin-top: 5px;
  font-size: 16px;
  line-height: 16px;
  color: #7b7b7b;
}
