.BlogDetails{
  min-height: 600px;
}
.BlogDetails .AboutUsSide{
  width: 375px;
}
.BlogDetails .AboutUsSideOptionOthers{
  overflow: visible;
}
.BlogDetails .AboutUsImage{
  height: 400px;
}
.BlogDetails .FixedWidth{
  padding: 25px 30px;
  display: flex;
}
.BlogDetails .ProductNavigationWrapper{
  z-index: 15;
  position: relative;
}
.BlogDetailsHeader{
  display: flex;
  align-items: flex-end;
  margin-top: -60px;
  margin-left: -20px;
  padding: 25px 25px;
  border-radius: 0 0 5px 5px;
  background: rgba(0,0,0,.05);

  position: relative;
}
.BlogDetailsHeaderBackground{
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  filter: blur(30px);
}
.BlogDetailsHeader::before{
  content: "";
  width: calc(100% + 150px);
  height: calc(100% + 150px);
  position: absolute;
  bottom: -75px;
  left: -75px;
  z-index: 4;
  background: linear-gradient(to top right, rgba(248,241,241,1), rgba(248,241,241,.1));
}
.BlogDetailsHeaderDetails{
  flex: 1;
  position: relative;
  z-index: 5;
  padding-right: 25px;
  margin-bottom: 25px;
}
.BlogDetailsHeaderTitle{
  font-size: 31px;
  line-height: 32px;
  font-weight: bold;
  max-width: 500px;
}
.BlogDetailsHeaderDate{
  margin-bottom: 10px;
  padding: 0 5px;
  font-size: 12px;
  color: #e0197d;
  font-weight: bold;
  font-size: 15px;
}
.BlogDetailsHeaderImage{
  width: 650px;
  height: 350px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #efefef;
  background-size: cover;
  background-position: center;

  position: relative;
  z-index: 20;

  margin-bottom: -50px;
}
.BlogDetailsContent{
  display: flex;
  margin-top: 75px;
  position: relative;
  margin-bottom: 50px;
  margin-right: 25px;

  position: relative;
  z-index: 5;
}
.BlogDetailsContentTextView{
  flex: 1;
  position: relative;
}
.BlogDetailsContentText{
  flex: 1;
  line-height: 22px;
  font-size:15px;
}
.BlogDetailsSide{
  margin-left: 50px;
  width: 500px;
  background: #fff;
  padding: 20px 15px;
  border-radius: 5px;
}
.BlogDetailsSideTitle{
  font-size: 16px;
  font-weight: bold;
  padding: 0 5px;
  padding-bottom: 20px;
  border-bottom: solid 1px rgba(0,0,0,.1);
}
.BlogDetailsSideItems{
  margin-top: 10px;
  margin-bottom: -15px;
}
.BlogDetailsSideItems .HomeContentItemNewsItem{
  padding: 10px 10px;
  align-items: flex-start;
}
.BlogDetailsSideItems .HomeContentItemNewsItemDetailsTitle{
  font-size: 15px;
}
.BlogDetailsSideItems .HomeContentItemNewsItemImage{
  height: 80px;
}
.BlogDetailsProduct{
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 20px;
}
.BlogDetailsProductImageWrapper{
  display: flex;
  justify-content: center;
  align-items: center;

  height: 200px;
  width: 250px;
  border-radius: 5px;

  background: rgba(255, 255, 255, .5);
}
.BlogDetailsProductImage {
  max-height: calc(100%);
  max-width: calc(100%);
}
.BlogDetailsProductInfo{
  margin-left: 20px;
  padding-left: 20px;
  border-left: solid 1px rgba(0, 0, 0, .05);
  padding-top: 8px;
  padding-bottom: 8px;

  width: calc(100%);
}
.BlogDetailsProductInfoTitle{
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
}
.BlogDetailsProductBtn{
  color:#fff;
  background: #e0197d;

  padding: 6px 12px;
  border-radius: 20px;

  font-size: 14px;
  font-weight: bold;

  display: flex;
  align-items: center;
}
.BlogDetailsProductBtn:hover{
  cursor: pointer;
}
.BlogDetailsProductInfoCat{
  margin-top: 5px;
  line-height: 22px;
  font-size: 14px;
}
.BlogDetailsProductInfoPriceContent{
  display: flex;
  align-items: flex-end;
  font-weight: bold;

  margin-top: 20px;
}
.BlogDetailsProductInfoPricePre{
  font-size: 12px;
  line-height: 13px;
  font-weight: bold;
  color: #e0197d;
  margin-right: 5px;
}
.BlogDetailsProductInfoPrice{
  font-size: 23px;
  line-height: 23px;
  font-weight: bold;
  color: #e0197d;
}
.BlogDetailsProductInfoPriceSub{
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  color: #e0197d;
  margin-left: 3px;
}
.BlogDetailsProductDescrWrapper{
  display: block;
  margin-top: 15px;
  max-width: 600px;
}
.BlogDetailsProductDescrTitle{
  font-weight: bold;
  user-select: none;
  font-size: 14px;
}
.BlogDetailsProductDescr{
  line-height: 22px;
  font-size: 14px;
}
