@media (min-width: 1100px){

  .HideDesktop{

  }
  .ShowMobile{
    display: none;
  }
  .ProductsSideItemsWrapper.ShowMobile{
    display: flex;
  }

}


@media (max-width: 1250px){
  .OrderItemOptionsButtonText{
    display: none;
  }

  .OrderItemOptions{
    margin: -5px;
  }
  .OrderItemOptionsButton:not(.SettingsContentContentNavigation .OrderItemOptionsButton){
    margin: 5px;
  }
  .CartCouponViewWrapper{
    flex-direction: column;
    padding: 0;
  }
  .CartTotalsWrapper{
    margin-top: 15px;
  }
  .CartCouponView{
    width: calc(100%);
    padding: 15px 15px;
  }
  .CartTotals{
    width: calc(100%);
    padding: 15px 15px;
  }
  .CartItemOptions{
    margin-left: 15px;
  }
  .CartItem{
    flex-wrap: wrap;
    padding: 10px 15px;
  }
  .CartItemFlipper{
    margin: 15px 0;
  }
  .CartItemRestText{
    margin-left: 95px;
  }
  .CartItemDetails{
    border-right: 0;
    padding-right: 15px;
    margin-right: 0;
  }
  .CartItemPrice{
    margin-left: 0;
    border-left: 0;
    width: auto;
    justify-content: flex-end;
  }
  .CartItemPrice{
    flex: 1;
  }
}

@media (max-width: 1100px){

  .HideDesktop{
    display: none !important;
  }
  .ShowMobile{
    display: flex !important;
  }
  .modalContent{
    padding: 0 15px 15px 15px;
  }
  .modalHeader{
    padding: 15px 15px 15px 20px;
  }
  .modalContentOptions{
    margin-top: 15px;
    padding-top: 15px;
  }
  .MenuHeader{
    height: 110px;
    padding: 20px 14px 15px 30px;
  }
  .MenuOverlay{
    position: absolute;
    width: 100vw;
    flex: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 80;
  }
  .MenuNav{

  }
  .MenuNav.Main{
    z-index: 83;
  }
  .FixedWidth{
    padding: 0;
  }
  .HeaderSubNav{
    display: none;
  }
  .HeaderSubLeft{
    flex: none;
    width: calc(100%);
    padding: 0;
  }
  .HeaderMessages{
    display: block;
    overflow-x: scroll;
    padding: 0 10px;
    padding-bottom: 10px;
    margin: 0 -10px;
    margin-bottom: -10px;
  }
  .HeaderMessageItem{
    display: inline-block;
  }
  .HomeBannerImage{
    border-radius: 0;
  }
  .HomeBannerSideItemImage, .HomeContentItemSplitterIntro .HomeBannerSideItemImage{
    padding-left: 0;
    width: calc(100%);
    justify-content: center;
  }
  .HomeBannerSideItemImage{
    position: unset;
  }
  .HomeBannerSideItemImage::before, .HomeContentItemSplitterIntro::after{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }
  .HomeBannerImageView{
    flex-direction: column;
  }
  .HomeBannerWrapper{
    display: block;
    width: calc(100%);
    margin: 0;
    overflow: hidden;
  }
  .HomeBannerArrow.Left{
    left: 5px;
  }
  .HomeBannerArrow.Right{
    right: 5px;
  }
  .HomeBannerSale{
    top: 20px;
    right: 15px;
    bottom: auto;
  }
  .HomeBannerPagination{
    bottom: 10px;
    right: 10px;
  }
  .HomeBannerModal{
    position: static;
    border-radius: 0;
    bottom: 0;
    width: calc(100% + 10px);
  }
  .HomeBannerModalTitle{
    font-size: 25px;
    line-height: 28px;
  }
  .HomeBannerModalText{
    font-size: 16px;
    line-height: 18px;
  }
  .HomeBannerSide{
    width: calc(100%);
    margin-left: 0;
  }
  .HomeBannerSideItem{
    display: block;
    padding-top: 15px;
    margin-top: 0;
    min-height: 300px;
    border-radius: 0 !important;
  }
  .HomeContentItemSplitterIntro.Full{
    height: 300px;
    width: calc(100%);
  }
  .HomeContentItemSplitterIntro{
    width: calc(100%);
    margin-right: 0;
  }
  .HomeBannerSideItemDetails{
    padding-top: 15px;
    text-align: left;
  }
  .HomeBannerSideItem:first-child{
    margin-top: 0;
  }
  .HomeBannerSideItemOptions{
    justify-content: flex-start;
  }
  .HomeBannerSide:hover .HomeBannerSideItem:not(:hover){
    opacity: 1 !important;
    filter: brightness(1) !important;
  }
  .HomeBannerPagination>a.Selected{
    background: #fff !important;
  }
  .HomeContentItemContent{
    margin-bottom: -15px;
    margin-top: 25px;
    overflow-x: scroll;
    padding: 0 10px;
    padding-bottom: 15px;
  }
  .HomeContentItem{
    padding: 20px 0;
  }
  .HomeContentItemSplitterItemsWrapper{
    flex: none;
    width: calc(100%);
    display: block;
    height: auto;
  }
  .HomeContentItemSplitterItemsWrapper .HomeContentItemTitleView{
    position: relative;
  }
  .HomeContentItemTitle{
    font-size: 22px;
    line-height: 25px;
  }
  .HomeContentItemSplitter{
    margin: 0 -15px;
    width: calc(100% + 15px);
    flex-direction: column;
  }
  .HomeContentItemSplitterItems{
    padding: 20px 10px 10px 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .HomeContentItemNewsBlock{
    display: block;
  }
  .HomeContentItemNewsHighlighted{
    display: block;
    height: auto;
    border-radius: 0;
  }
  .HomeContentItemNewsHighlightedModal{
    width: calc(100%);
    position: relative;
    padding: 25px;
    padding-top: 50px;
  }
  .HomeContentItemNewsHighlightedModalTitle{
    font-size: 30px;
    line-height: 30px;
  }
  .HomeContentItemNewsHighlightedModalInfo{
    display: block;
    margin: 0 -10px;
    margin-top: 20px;
  }
  .HomeContentItemNewsHighlightedModalInfoItem{
    flex: none;
    display: inline-block;
    margin: 10px;
  }
  .HomeContentItemNewsHighlightedImage{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
  .HomeContentItemNewsItems{
    display: block;
    width: calc(100%);
  }
  .HomeContentItemNewsItem{
    display: block;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .HomeContentItemNewsItemImage{
    height: 125px !important;
    width: calc(100%);
  }
  .HomeContentItemNewsItemDetails{
    flex: none;
    margin-top: 15px;
  }
  .HomeBannerSale{
    height: 140px;
    width: 140px;
  }
  .HomeBannerSalePrice, .HomeBannerSale.Large .HomeBannerSalePrice{
    font-size: 40px;
    line-height: 40px;
  }

  .FooterBar.mini .FooterCopyWrapper{
    width: calc(100%);
    text-align: center;
  }
  .FooterBar{
    padding: 25px 15px !important;
  }
  .FooterSections{
    display: block;
  }
  .FooterSection{
    margin-bottom: 15px;
    margin-right: 0;
  }
  .FooterCreator{
    display: block;
  }
  .FooterCreaterPre{
    display: inline-block;
    white-space: nowrap;
  }
  .FooterNewsletterWrapper{
    display: block;
    margin-top: 15px;
    padding: 0 5px;
  }
  .FooterNewsletterPre{
    margin-bottom: 15px;
    font-size: 14px;
    margin-right: 0;
    text-align: center;
  }
  .FooterNewsletter{
    display: block;
    width: calc(100%);
    /* padding: 0 10px; */
  }
  .FooterNewsletter input{
    flex: none;
    height: 40px;
  }
  .MenuWrapper{
    display: block;
  }
  .MenuNav.Sub{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .HeaderNavOptions{
    display: none;
  }
  .HeaderNavSearchWrapper{
    position: absolute;
    top: 70px;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: -1;
    padding: 0 10px;
    width: 100%;
  }
  .HeaderNavSearch{
    width: 100%;
  }
  .searchItemsView{
    left:0;
    right:0;
    top: 20px;
  }
  .HeaderNavSearchWrapper:focus-within .searchItemsView {
    transform: translateY(30px);
  }
  .HeaderNavButtons{
    flex: 1;
    justify-content: flex-end;
  }


  /* BLOGS */
  .BlogsHeader{
    flex-direction: column-reverse;
    padding: 15px 15px;
    padding-top: 25px;
  }
  .BlogsHeaderDetails{
    flex: none;
    margin-top: 25px;
    padding: 0;
    margin-right: 0;
  }
  .BlogsHeaderImageFloatWrapper{
    padding-right: 0;
  }
  .BlogsHeaderImageFloat{
    width: calc(100%);
    height: 200px;
    transform: none !important;
  }
  .BlogsHeaderDetailsTitle, .VacanciesHeaderTitle{
    font-size: 25px;
    line-height: 28px;
  }
  .BlogsItems{
    padding: 10px 0;
  }
  .BlogsItem, .VacancyItem{
    max-width: none;
    margin: 5px;
  }
  .StoresItemsHeader{
    padding: 15px 15px;
  }
  .StoresItemsHeaderTitle{
    font-size: 22px;
    line-height: 25px;
  }
  .BlogDetailsContent{
    display: block;
    margin-right: 0;
    margin-top: 15px;
  }
  .BlogDetailsContentTextView{
    padding: 15px 20px;
  }
  .BlogDetailsSide{
    width: auto;
    margin-left: 0;
    margin-top: 25px;
  }
  .BlogDetailsHeader{
    flex-direction: column-reverse;
    padding: 15px 15px;
    padding-top: 0;
    margin-top: 25px;
    margin-left: 0;
    overflow: hidden;
    background: transparent;
  }
  .BlogDetailsHeaderImage{
    width: calc(100%);
    height: 200px;
    transform: none !important;
    margin-bottom: 0;
  }
  .BlogDetailsHeaderDetails{
    flex: none;
    margin-top: 25px;
    padding: 0;
    margin-right: 0;
    width: calc(100%);
    border-bottom: solid 1px rgba(0,0,0,.1);
    padding-bottom: 15px;
  }
  .BlogDetailsHeaderTitle{
    font-size: 22px;
    line-height: 25px;
  }
  .BlogDetailsHeader::before{
    content: none;
  }
  .BlogDetailsHeaderBackground{
    display: none;
  }
  .GlobTexture{
    display: none;
  }


  /* PRODUCTS */
  .ProductDetails{
    flex-direction: column;
  }
  .ProductDetailsImageWrapper{
    flex-direction: column;
    width: calc(100%);
    padding: 0 10px !important;
    margin-right: 0;
  }
  .ProductDetailsImageView{
    width: calc(100%) !important;
    height: 350px !important;
  }
  .ProductDetailsImageSide{
    display: flex;
    align-items: center;
    overflow-x: scroll;
    margin-left: 0;
    margin-top: 15px;
  }
  .ProductDetailsImageSideItem{
    margin-right: 10px;
    margin-top: 0;
  }
  .ProductDetailsContent{
    padding: 15px;
  }
  .ProductDetailsContentAddWrapper{
    flex-wrap: wrap;
    padding-right: 0;
  }
  .ProductDetailsContentAdd{
    display: flex;
    width: calc(100%);
    margin-left: 0 !important;
    margin-top: 15px;
  }
  .ProductDetailsContentAddText{
    flex: 1;
  }
  .ProductDetailsRelatedTitle{
    padding: 0 0;
  }
  .ProductDetailsRelated{
    padding: 0 15px;
    padding-bottom: 25px;
  }
  .ProductsItemsItems{
    padding: 15px;
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ProductItem{
    display: block;
    width: calc(49%);
    margin: 0;
    margin-bottom: 5px;
  }
  .ProductDetailsRelatedItems{
    margin: 0 -15px;
    padding: 0 15px;
  }
  .ProductItemContainer{
    width: calc(100%);
    /* max-width: 400px; */
  }
  .ProductsWrapper{
    flex-direction: column;
  }
  .ProductsSide{
    padding-right: 0;
    max-width: none;
  }
  .ProductItemImage{
    height: 200px;
  }
  .ProductsSideHighlight{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 25px;
  }
  .ProductsSideHeaderTitle{
    font-size: 18px;
    line-height: 16px;
  }
  .ProductsSideHeaderSubtitle{
    font-size: 18px;
  }
  .ProductsSideCategoryItemTitle{
    font-size: 16px;
  }
  .ProductsItemsHeaderTitle{
    font-size: 22px;
    line-height: 25px;
  }
  .ProductsItemsHeader{
    padding: 0 20px;
  }
  .ProductsSideItemsWrapper{
    display: none;
    margin-top: -14px;
  }
  .ProductsSideHeader{
    background: rgba(255,255,255,.75);
    height: 44px;
    border-radius: 5px;
    margin: 0 15px;
    margin-bottom: 25px;
  }


  /* CUSTOMER SERVICE */
  .CustomerServiceWrapper{
    height: auto;
    flex-direction: column;
  }
  .CustomerServiceChatContentItems{
    padding: 10px 10px;
  }
  .CustomerServiceSide{
    margin-right: 0;
    padding-right: 0;
  }
  .CustomerService{
    height: auto;
  }
  .FaqItemQuestionView{
    padding: 15px 15px;
  }
  .FaqItemAnswer{
    padding: 15px 15px;
  }
  .CustomerServiceContent{
    padding-left: 0;
  }
  .CustomerAIMessages.Show{
    width: calc(100vw - 20px);
  }
  .CustomerServiceChat{
    /* width: calc(100vw - 20px); */
    width: calc(100vw);
    border-radius: 0;
  }
  .CustomerAI .CustomerServiceChat{
    width: calc(100%);
  }
  .CustomerServiceChatContent{
    height: calc(100vh - 450px);
    flex: none;
  }


  .CartItemsEmpty{
    display: flex;
    padding: 10px 15px;
  }
  .CartWrapper{
    height: auto;
    flex-direction: column;
  }
  .CartPaymentContent{
    padding: 0 15px;
  }
  .CartPlaced{
    padding: 15px;
    padding-bottom: 25px;
    width: calc(100%);
  }
  .CartPlacedAnimationView{
     margin-top: -15px;
  }
  .CartContentTitle, .CartLaterTitle{
    padding: 5px 15px;
    font-size: 18px;
  }
  .CartEmptyView{
    width: calc(100%);
  }
  .CartPlacedModalOptions{
    flex-direction: column;
  }
  .CartPlacedModalOptionsButton{
    margin-left: 0;
    margin-top: 10px;
  }
  .CartPlacedModalOptionsButton:first-child{
    margin-top: 0;
  }
  .Cart{
    height: auto;
  }
  .CartContent{
    margin-right: 0;
    padding-right: 0;
    padding: 15px 0;
    padding-bottom: 0;
  }
  .CartTotalsItem.Discount{
    margin-right: 0;
    width: calc(100%);
    margin-bottom: 10px;
  }
  .CartPayment{
    height: auto;
    width: calc(100%);
    border-radius: 0;

    padding: 15px 0;
  }
  .CartItemRestWrapper{
    width: calc(100%);
  }
  .CartItemDetailsTitle{
    font-size: 14px;
  }


  /* STORES */
  .StoresItems{
    padding-left: 0;
  }
  .Stores{
    height: auto;
    flex-direction: column;
  }
  .StoresWrapper{
    position: static;
  }
  .StoresMap{
    flex: none;
    height: 500px;
  }


  /* ACCOUNT */
  .StoresItems{
    padding-left: 0;
  }
  .Settings{
    height: auto;
  }
  .Settings .FixedWidth{
    flex-direction: column;
  }
  .SettingsSide{
    width: calc(100%);
    padding-bottom: 0;
  }
  .SettingsContent{
    flex: none;
    padding: 15px 15px 0 15px;
    min-height: 600px;
  }
  .SettingsContentTitleView{
    height: 57px;
    padding: 0 10px;
    padding-bottom: 15px;
  }
  .SettingsContentTitle{
    font-size: 22px;
    line-height: 25px;
  }
  .SettingsContentContent{
    flex: none;
    padding: 0;
  }
  .settingsContentReal.Flexed{
    padding: 0;
  }
  .SettingsSide.Full{
    width: calc(100%) !important;
  }
  .SettingsContentContent.List .SettingsContentNavigation{
    display: none;
  }
  .OrderV2Wrapper{
    width: calc(100%);
  }
  .OrderV2Wrapper{
    margin: 0;
  }
  .OrderV2Wrapper:first-child{
    margin-top: 0;
  }
  .OrderV2Options{
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0 5px 5px;
  }
  .OrderV2OptionsPre{
    width: calc(100%);
    padding-right: 0;
    border-right: 0;
  }
  .OrderV2OptionsPreOptions{
    width: calc(100%);
    margin-left: 0;
    margin-top: 5px;
    flex-direction: column;
    align-items: flex-end;
  }
  .OrderV2OptionsPreOptionsPrice{
    width: calc(100%);
    margin: 5px 0;
    text-align: left;
  }
  .OrderV2OptionsPreOptionsStatusView{
    width: calc(100%);
  }
  .OrderV2OptionsPreOptionsButton{
    margin-top: 10px;
    width: calc(100%);
    height: 32px;
  }
  .DetailViewWrapper{
    padding: 0;
  }
  .modalCouponItem{

  }
  .modalCouponItemDetailsWrapper{

    flex-direction: column;
  }
  .modalCouponItemSub{
    width: calc(100%);
    margin-left: 0;
    padding-left: 0;
    border-left: 0;
    justify-content: flex-start;
  }
  .modalCouponItemSubPriceNew{
    width: calc(100%);
    text-align: right;
  }
  .modalDeliveryView{
    flex-direction: column;
  }
  .modalCancelViewWrapper{
    width: calc(100%);
    margin-left: 0;
    padding-left: 0;
    border-left: 0;
    margin-top: 10px;
  }
  .modalCancelView{
    display: flex;
    width: calc(100%);
    flex-direction: column;
    align-items: flex-end;
  }
  .ModalSideOverlay{
    position: relative;
    height: 0;
  }
  .modalAccountView{
    flex-direction: column;
    align-items: flex-start;
  }
  .modalAccountDetailsOptions{
    margin-top: 10px;
  }
  .ModalSideOverlayBackgroundText{
    display: none;
  }
  .ModalSideContent{
    padding: 15px 0;
  }
  .OrderItem{
    display: block;
    padding: 10px 10px;
  }
  .OrderItemOptionsButton:not(.SettingsContentContentNavigation .OrderItemOptionsButton){
    margin: 0;
  }
  .OrderItemDetailsText.StatusExtra{
    display: block;
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
  }
  .OrderItemDetailsText.Date{
    display: block;
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
  }
  .OrderItemOptions{
    display: flex;
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px;
    justify-content: flex-start;
  }
  .OrderItemOptionsButton{
    width: calc(100%);
    justify-content: center;
  }
  .OrderItemOptionsButtonText{
    display: inline-block !important;
  }
  .SettingsContentItem{
    display: block;
    max-width: none;
    margin: 0;
    margin-bottom: 5px;
    padding: 15px;
  }
  .NotificationWrapper{
    padding: 12px 15px;
    margin: 0;
  }
  .NotificationWrapper:first-child{
    margin-top: 0;
  }
  .SettingsHeader{
    display: none;
  }
  .SettingsSideItems{
    display: flex;
    align-items: center;

    margin-top: 15px;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0 15px;
    padding-bottom: 15px;
  }
  .SettingsItem{
    white-space: nowrap;
    overflow: visible;
  }
  .SettingsContentContent.List{
    flex: 1;
  }
  .ModalSideOverlayBackground{
    display: none;
  }
  .ModalSide{
    border-left: 0;
  }

  .Login{
    flex-direction: column;
  }
  .LoginContent{
    max-width: none;
  }
  .LoginImageView{
    flex: none;
    height: 300px;
  }
  .LoginForm{
    flex: none;
    padding: 15px;
  }
  .LoginFormSignupView{
    margin: 0;
    margin-top: 15px;
  }
  .LoginFormOptions{
    flex-direction: column;
  }
  .LoginFormOptionsButton{
    flex: none;
    width: calc(100%);
    margin: 0;
    margin-top: 10px;
    margin-right: 0 !important;
    border-radius: 18px !important;
  }
  .LoginFormOptionsButton.Left{
    margin-top: 0;
  }

  .modalContentCartAnimationView{
    margin: 0 -15px;
    width: calc(100% + 30px);
  }


  /* Info page */
  .InfoSplitter{
    flex-direction: column;
  }
  .InfoSplitterSide{
    width: calc(100%);
    min-height: inherit;
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
  }
  .InfoSplitterInfo{
    flex: none;
    margin-top: 20px;
    width: calc(100%);
  }

  /* LOGIN POPUP */
  .modalContentContent.Row{
    flex-direction: column;
  }
  .modalContentContent.Row .LoginFormSignupView{
  	margin-left: 0;
  }
  .CustomerAI{
    padding: 10px;
  }
  .CustomerAIMessages{
    width: calc(100%);
    margin-bottom: -55px;
    margin-right: 0;
    z-index: 25;
  }
  .CustomerAI.Show .CustomerAIButton{
    opacity: 0;
  }
  .CustomerAIMessages{
    height: auto;
  }
  .BlockItemImage{
    margin: 0 -20px;
    overflow-x: scroll;
    padding: 0 20px;
    padding-bottom: 20px;
    margin-bottom: -20px;
  }
  .BlockItemImage.Full{
    padding: 0;
    margin: 0 -20px;
    width: calc(100% + 40px);
    border-radius: 0;
    margin-top: 20px;
  }
  .BlockItemImage.Full>img{
    height: 350px;
  }


  /* Vacancies */
  .BlogsDetailSplitter{
    flex-wrap: wrap-reverse;
  }
  .BlogsDetailsSideWrapper{
    max-width: none !important;
    margin-right: 15px;
    margin-left: 15px;
  }
  .BlogsDetailsContent{
    padding: 20px 20px;
  }
  .BlogsDetailsApplyView{
    width: calc(100% - 30px);
    margin: 0 15px;
    margin-top: 20px;
  }

  .VacancyDetailSplitter{
    flex-direction: column;
  }
  .VacancyDetailSplitterContent{
    flex: none;
  }
  .VacancyDetailsSideWrapper{
    max-width: none;
    margin-left: 0;
    width: calc(100% - 20px);
    margin: 0 10px;
  }
  .VacancyDetailsApplyView{
    height: auto;
  }

  .AboutUs .FixedWidth, .BlogDetails .FixedWidth{
    padding: 15px 15px;
    flex-direction: column;
    flex-flow: column-reverse;
  }
  .AboutUsTitle{
    font-size: 30px;
    line-height: 35px;
  }
  .AboutUsSide{
    width: calc(100%) !important;
    margin-right: 0;
    margin-bottom: 25px;
  }
  .AboutUsContent{
    flex: none;
    width: calc(100%);
    padding-left: 0;
    margin-left: 0;
    overflow: visible;
  }
  .AboutUsSideOption{
    margin: 0;
    margin-bottom: 10px;
  }
  .AboutUsImage{
    height: 300px;
  }
  .AboutUsSideTitle{
    font-size: 30px;
    line-height: 35px;
  }
  .AboutUsSideOptionsTitle{
    padding: 0 10px;
  }
  .AboutUsSideOptionSocials{
    margin: 0 0;
  }
  .AboutUsSideOptionOthers{
    margin: 0 0;
    margin-top: 15px;
  }
  .AboutSideFooterView{
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .VacancyDetailsContent{
    padding-right: 15px;
  }
  .ProductsEmpty{
    left: 15px;
    top: 15px;
    right: 15px;
  }
  .Products{
    min-height: 0;
    padding-bottom: 0;
  }
  .ProductsItemsWrapper{

  }
  .ProductsEmptyWrapper{
    height: calc(100vh - 400px);
    position: relative;
  }
}
