.Settings{
  /* height: calc(100vh - 161px - 55px); */
  height: calc(100vh - 115px - 25px);
}
.Settings .FixedWidth{
  display: flex;
  flex-direction: row;
  height: calc(100%);
}
.SettingsSide{
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 25px;
  border-right: solid 1px rgba(0,0,0,.075);
  padding: 25px 0;

  overflow-y: scroll;

  transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}
.SettingsSide.Full{
  width: 270px;
}
.GlobSettingsScroll{
  flex: 1;
  overflow-y: scroll !important;
  position: relative;
  padding-bottom: 20px !important;
}
.SettingsSideHeader{
  display: flex;
  align-items: center;

  padding: 10px 15px;
  border-left: solid 1px rgba(0,0,0,.075);
  border-top: solid 1px rgba(0,0,0,.075);
  border-bottom: solid 1px rgba(0,0,0,.075);
}
.SettingsSideHeaderAvatar{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #efefef;
  background-size: cover;
  background-position: center;

  overflow: hidden;
}
.SettingsSideHeaderAvatar:hover .SettingsSideHeaderIconContainer{
  scale: 1;
  opacity: 1;

  cursor:pointer;
}
.SettingsSideHeaderIconContainer{
  width: calc(100%);
  height: calc(100%);
  border-radius: 50%;
  background-color: #000;

  display: flex;
  justify-content: center;
  align-items: center;

  scale: .6;
  opacity: 0;
	transition: all 150ms ease-in-out;
}
.SettingsSideHeaderDetails{
  flex: 1;
  margin-left: 15px;
}
.SettingsSideHeaderDetailsName{
  font-weight: bold;
  font-size: 16px;
}
.SettingsSideHeaderDetailsSubtitle{
  margin-top: 2px;
  color: #e0197d;
  font-weight: bold;
  font-size: 12px;
  padding: 0 2px;
}
.SettingsItem{
  display: flex;
  align-items: center;

  height: 44px;
  white-space: nowrap;
  overflow: hidden;

  padding: 0 20px;
}
.SettingsItemNew{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #febe23;
  margin-left: 8px;
}
.SettingsItemCount{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  padding: 0 5px;
  border-radius: 10px;
  background: #febe23;
  color: #2b2b2b;
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
}
.SettingsHeader{
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;

  padding: 0 25px;
}
.SettingsHeaderText{
  font-weight: bold;
  color: #e0197d;
  font-size: 13px;
}
.SettingsItem.Selected{
  color: #e0197d;
  background: rgba(255,255,255,1) !important;
}
.SettingsItemTextView{
  display: flex;
  align-items: center;
  flex: 1;
}
.SettingsItemText{
  font-size: 17px;
  line-height: 16px;
  font-weight: bold;
  margin-left: 10px;
}
.SettingsItem:hover{
  background: rgba(255,255,255,.5);
}
.SettingsContent{
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 25px 0 25px;
  background: rgba(255,255,255,.75);
  border-top: solid 1px rgba(0,0,0,.075);
  margin-top: -1px;
}
.SettingsContentTitleView{
  display: flex;
  align-items: center;
  height: 57px;
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 0;
  padding-bottom: 20px;
  border-bottom: solid 1px rgba(0,0,0,.075);
}
.SettingsContentTitleWrapper{
  flex: 1;
}
.SettingsContentTitle{
  font-weight: bold;

  font-size: 29px;
  letter-spacing: -0.2px;
}
.SettingsContentSubtitle{
  margin-left: 2px;
  margin-top: 5px;
  opacity: .75;
  font-size: 13px;
}
.SettingsContentContent{
  flex: 1;
  padding: 0 10px;
  padding-top: 25px;
  overflow: hidden;
  position: relative;

  user-select: none;
}
.SettingsContentContent.Modal .settingsContentReal{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.SettingsContentContent.Modal .ModalSideOverlay{
  height: auto;
}
.SettingsContentContent.List{
  display: flex;
  flex-direction: column;
  margin: 0 -15px;
  padding: 0 0;
}
.SettingsContentContent.List .GlobSettingsScroll{
  padding-left: 10px;
  padding-right: 10px;
}

.SettingsContentContent.List .SettingsContentNavigation{
  padding-left: 30px;
  padding-right: 30px;
}
.SettingsContentItem{
  display: inline-block;
  vertical-align: top;
  width: calc(100%);
  border: solid 1px rgba(0,0,0,.025);
  max-width: calc(33% - 7px);
  background: #fdfdfd;
  border-radius: 5px;
  margin: 5px;
  position: relative;
  padding: 25px;

  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}
.SettingsContentItem:active{
  transform: scale(0.98);
  box-shadow: 0 5px 40px rgba(0,0,0,.075);
}
.SettingsContentItem:hover{
  opacity: 1;
}
.SettingsContentItem:hover .SettingsContentItemIcon{
  color: #e0197d;
}

.SettingsContentItemIcon{
  position: absolute;
  top: 25px;
  right: 25px;
}
.SettingsContentItemTitle{
  opacity: .75;
  font-weight: bold;
}
.SettingsContentItemSubtitleView{
  margin-top: 5px;
}
.SettingsContentItemSubText{
  margin-top: 5px;
  font-size: 12px;
  color: #d63636;
}
.SettingsContentItemSubtitle{
  flex: 1;
  margin-right: 15px;
  font-size: 16px;
  font-weight: bold;
}
.SettingsContentOptionsLogout{
  margin-top: 50px;
}
.SettingsContentOptionsLogout .SettingsContentItem{
  color: #d63636;

  background: rgba(214, 54, 54, .1);
}


/* ORDERS */
.SettingsContentNavigation{
  display: flex;
  align-items: center;
  width: calc(100%);
  margin-top: 2px;
  border-bottom: solid 1px rgba(0,0,0,.075);
}
.SettingsContentNavigation.Margin{
  margin-top: 0;
}
.SettingsContentNavigationItem{
  flex: 1;
  font-weight: bold;
  color: #7b7b7b;
  margin-left: 10px;
  padding: 0 10px;
  font-size: 12px;
  height: 40px;
  padding-bottom: 10px;
  line-height: 38px;
  cursor: pointer;
  transform: translateY(0);
  transition: all 150ms ease-in-out;
}
.SettingsContentNavigationItem:hover{

}
.SettingsContentNavigationItem.First{
  padding-left: 0;
  margin-left: 0;
}
.SettingsContentNavigationItem.ID{
  flex: none;
  width: 80px;
  padding-left: 0;
  margin-left: 0;
}
.SettingsContentNavigationItem.IDSmall{
  flex: none;
  width: 50px;
  padding-left: 0;
  margin-left: 0;
}
.SettingsContentNavigationItem.Order{
  flex: none;
  width: 40px;
}
.SettingsContentNavigationItem.Coupon{
  flex: none;
  width: 160px;
}
.SettingsContentNavigationItem.Active{
  flex: none;
  width: 45px;
}
.SettingsContentNavigationItem.ActiveLarge{
  flex: none;
  width: 80px;
}
.SettingsContentNavigationItem.Amount{
  flex: none;
  width: 110px;
}
.SettingsContentNavigationItem.StatusMid{
  flex: none;
  width: 110px;
}
.SettingsContentNavigationItem.Title{
  flex: none;
  width: 250px;
}
.SettingsContentNavigationItem.Content{
  flex: none;
  width: 350px;
}
.SettingsContentNavigationItem.Name{
  flex: none;
  width: 155px;
}
.SettingsContentNavigationItem.Code{
  flex: none;
  width: 240px;
}
.SettingsContentNavigationItem.Link{
  flex: none;
  width: 80px;
}
.SettingsContentNavigationItem.NameMid{
  flex: none;
  width: 175px;
}
.SettingsContentNavigationItem.NameLarge{
  flex: none;
  width: 200px;
}
.SettingsContentNavigationItem.EmailFixed{
  flex: none;
  width: 165px;
}
.SettingsContentNavigationItem.Descr{
  flex: none;
  width: 300px;
}
.SettingsContentNavigationItem.Stock{
  flex: none;
  width: 60px;
}
.SettingsContentNavigationItem.Image{
  flex: none;
  width: 65px;
}
.SettingsContentNavigationItem.Phone{
  flex: none;
  width: 115px;
}
.SettingsContentNavigationItem.Status{
  flex: none;
  width: 200px;
}
.SettingsContentNavigationItem.Type{
  flex: none;
  width: 75px;
}
.SettingsContentNavigationItem.StatusExtra{
  flex: none;
  width: 250px;
}
.SettingsContentNavigationItem.Date{
  flex: none;
  width: 175px;
}
.SettingsContentNavigationItem.Question{
  flex: none;
  width: 275px;
}
.SettingsContentNavigationItem.Answer{
  flex: none;
  width: 250px;
}
.SettingsContentNavigationItem.Options{
  flex: none;
  width: 150px;
}
.SettingsContentNavigationItem.OptionsBig{
  flex: 1;
  white-space: nowrap;
}
.OrderItem{
  display: flex;
  align-items: center;
  width: calc(100%);
  padding: 10px 20px;
  cursor: pointer;

  overflow: hidden;
}
.OrderItem.Selected{
  background: rgb(253, 244, 248) !important;
  border-left: solid 2px #e0197d;
  
}
.OrderItem.Aligned{
  align-items: flex-start;
}
.OrderItem:hover{
  background: rgba(255,255,255,1);
}
.OrderItemOptionsArrow{
  margin-top: 8px;
}
.OrderItemImageView{
  width: 120px;
}
.OrderItem>.OrderItemDetailsText.ID{
  width: 80px;
}
.OrderItem>.OrderItemDetailsText.IDSmall{
  width: 50px;
}
.OrderItem>.OrderItemDetailsText.Order{
  width: 40px;
}
.OrderItem>.OrderItemDetailsText.Coupon{
  width: 160px;
}
.OrderItem>.OrderItemDetailsText.Active{
  width: 45px;
}
.OrderItem>.OrderItemDetailsText.ActiveLarge{
  width: 80px;
}
.OrderItem>.OrderItemDetailsText.Amount{
  width: 110px;
}
.OrderItem>.OrderItemDetailsText.StatusMid{
  width: 110px;
}
.OrderItem>.OrderItemDetailsText.Link{
  width: 80px;
}
.OrderItem>.OrderItemDetailsText.Title{
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderItem>.OrderItemDetailsText.Content{
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderItem>.OrderItemDetailsText.Name{
  width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderItem>.OrderItemDetailsText.Code{
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderItem>.OrderItemDetailsText.NameMid{
  width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderItem>.OrderItemDetailsText.NameLarge{
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderItem>.OrderItemDetailsText.EmailFixed{
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderItem>.OrderItemDetailsText.Descr{
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderItem>.OrderItemDetailsText.Stock{
  width: 60px;
}
.OrderItem>.OrderItemDetailsText.Image{
  width: 65px;
}
.OrderItem>.OrderItemDetailsText.Phone{
  width: 115px;
}
.OrderItemDetailsTextImage{
  width: 50px;
  height: 40px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, .05);
}
.ProductImage{
  background-size: contain;
  background-repeat: no-repeat;
}
.Square.ProductImage{
  width: 120px;
}
.OrderItemDetailsTextImage.Circle{
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
}
.OrderItem>.OrderItemDetailsText.Email{
  flex: 1;
}
.OrderItem>.OrderItemDetailsText.Status{
  width: 225px;
}
.OrderItem>.OrderItemDetailsText.Type{
  width: 75px;
}
.OrderItem>.OrderItemDetailsText.StatusExtra{
  width: 250px;
}
.OrderItem>.OrderItemDetailsText.Question{
  width: 275px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.OrderItem>.OrderItemDetailsText.Answer{
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.OrderItem>.OrderItemDetailsText.Date{
  width: 175px;
  white-space: nowrap;
}
.OrderItemDetailsText{
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-left: 10px;
  border-left: solid 1px rgba(0,0,0,.075);
  font-size: 15px;
}
.OrderItemDetailsText .OrderV2OptionsPreOptionsStatus{
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.OrderItemDetailsText.DeliveryType{
  font-weight: bold;
  font-size: 12px;
}
.OrderItemDetailsText.Delivery{
  color: #e0197d;
}
.OrderItemDetailsImage{
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin-right: -5px;
  border: solid 2px #fff;
  border-radius: 50%;
  background-size: cover;
  background-color: rgba(0,0,0,.1);
  background-position: center;
}

.OrderItemDetailsTextOption{
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  height: 18px;
  line-height: 18px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 10px;

  cursor: pointer;
}
.OrderItemDetailsTextOption.Circle{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 18px;
}
.OrderItemDetailsTextOption.Success{
  background: #4bd63e;
}
.OrderItemDetailsTextOption.Error{
  background: #d53e3e;
}
.OrderItemDetailsTextOptionText{
  font-weight: bold;
  font-size: 10px;
}
.OrderItemDetailsText.Bold{
  font-weight: bold;
}
.OrderItemDetails{
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
  padding-left: 10px;
  border-left: solid 1px rgba(0,0,0,.075);
}
.OrderItemDetails .OrderItemDetailsText{
  margin-left: 0;
  padding-left: 0;
}
.OrderItemOptionsButtons{
  display: flex;
  align-items: center;
}
.OrderItemOptionsButton{
  display: flex;
  align-items: center;
  justify-content: center;
}
.OrderItemOptionsButtonDrop{
  display: flex;
  align-items: center;
  position: relative;
}
.OrderItemOptionsButtonDropItem{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;
  margin-left: 10px;
  white-space: nowrap;
  position: relative;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.OrderItemOptionsButtonDropView{
  display: none;
  position: absolute;
  top: 38px;
  right: 0;
  left: 10px;
  background: #fff;
  box-shadow: 0 0 50px rgba(0,0,0,.1);
  border-radius: 5px 5px 10px 10px;
  overflow: hidden;
}
.OrderItemOptionsButtonDropView.Open{
  display: block;
}
.OrderItemOptionsButtonDropViewItem{
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 20px;
  cursor: pointer;
}
.OrderItemOptionsButtonDropViewItem:hover{
  background: #f9f9f9;
}
.OrderItemOptions{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin-left: 10px;
  padding-left: 10px;
  border-left: solid 1px rgba(0,0,0,.075);
  flex-wrap: wrap;
}
.OrderItemOptions.Medium{
  width: 150px;
}
.OrderItemOptionsButton:first-child{
  margin-left: 0;
}
.OrderItemOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;
  margin-left: 10px;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.OrderItemOptionsButton.Accept{
  background: #47bb3c;
}
.OrderItemOptionsButton.Accept:hover{
  background: #53cc48;
}
.OrderItemOptionsButton.Delete{
  background: #d53e3e;
}
.OrderItemOptionsButton.Delete:hover{
  background: #df3c3c;
}
.OrderItemOptionsButton.DeleteInverted{
  color: #d53e3e;
  background: #f9e9e9;
}
.OrderItemOptionsButton.DeleteInverted:hover{
  background: #fbf0f0;
}
.OrderItemOptionsButton.AddInverted{
  color: #4bd63e;
  background: #ecfaeb;
}
.OrderItemOptionsButton.AddInverted:hover{
  background: #f1faf0;
}
.OrderItemOptionsButton.Edit{
  background: #e6e6e6;
  color: #2b2b2b;
}
.OrderItemOptionsButton.Edit:hover{
  background: #efefef;
}
.OrderItemOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;
  margin-left: 10px;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.OrderItemOptionsButtonText{
  margin-left: 10px;
  font-weight: bold;
  line-height: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}
.OrderItemDetailsText.ID, .OrderItemDetailsText.IDSmall{
  flex: none;
  font-weight: bold;
  border-left: 0;
  padding-left: 0;
  margin-left: 0;
}
.OrderItemDetailsText.First{
  border-left: 0;
  padding-left: 0;
  margin-left: 0;
}
.OrderItemDetailsSubtext{
  margin-top: 2px;
  font-size: 14px;
  color: #3b3b3b;
}
.OrderItemDetailsStatus{
  display: inline-block;
  padding: 0 10px;
  height: 20px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
}
.OrderItemDetailsText.Amount{

}
.OrderItemDetailsText.Stock{

}
.OrderItemDetailsText.More{
  color: #e0197d;
  font-weight: bold;
}
.OrderItemDetailsText.Amount b{
  margin-right: 5px;
  color: #3b3b3b;
  font-size: 14px;
}

.OrderV2Wrapper{
  display: flex;
  flex-direction: column;
  width: calc(100%);
  max-width: 800px;
  background: #fdfdfd;
  border: solid 1px rgba(0,0,0,.025);
  border-radius: 5px;
  margin: 5px 5px;
  position: relative;
  padding: 5px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}
.OrderV2Wrapper:hover{
  background: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,.01);
}
.OrderV2Wrapper:first-child{
  margin-top: 30px;
}
.OrderV2Items{
  display: flex;
  align-items: flex-end;
  width: calc(100%);
  overflow-x: scroll;
  padding: 15px 20px;
  background: rgba(0,0,0,.01);
  border-bottom: solid 1px rgba(0,0,0,.03);
  position: relative;
}
.OrderV2Wrapper:hover .OrderV2ItemsItem{
  margin-right: 10px;
  rotate: 0deg !important;
}
.OrderV2ItemsBackground{
  overflow: hidden;
  width: calc(100%);
  height: calc(100%);
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: 9;
  border-radius: 5px;

  background-size: cover;
  background-position: center;
  filter: blur(40px);
  opacity: .2;
}
.OrderV2ItemsItem{
  display: inline-block;
  vertical-align: bottom;
  max-height: 60px;
  max-width: 80px;
  rotate: -10deg;
  margin-right: -4px;
  transform-origin: center bottom;
  position: relative;
  z-index: 10;

  transition: all 300ms ease-in-out;
}
.OrderV2Options{
  display: flex;
  align-items: flex-end;
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.OrderV2OptionsPre{
  flex: 1;
  padding-right: 25px;
  border-right: solid 1px rgba(0,0,0,.05);
}
.OrderV2OptionsPreOrderNo{
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.2px;
}
.OrderV2OptionsPreOrderDate{
  margin-top: 5px;
}
.OrderV2OptionsPreOptions{
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.OrderV2OptionsPreOptionsStatusView{
  display: flex;
  align-items: center;
}
.OrderV2OptionsPreOptionsStatus{
  font-size: 15px;
}
.OrderV2OptionsPreOptionsPriceView{
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-right: 10px;
}
.OrderV2OptionsPreOptionsPricePre{
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: -2px;
}
.OrderV2OptionsPreOptionsPrice{
  font-weight: bold;
  font-size: 18px;
  margin-left: 2px;
  margin-top: -1px;
}
.OrderV2OptionsPreOptionsButton{
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 28px;
  line-height: 28px;
  font-weight: bold;
  background: #e0197d;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.OrderV2OptionsPreOptionsButton.Cancel{
  color: #3b3b3b;
  font-weight: normal;
  text-decoration: underline;
  background: transparent;
}
.OrderV2OptionsPreOptionsError{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #d63636;
  margin-left: 5px;
  margin-bottom: -1px;
  color: #fff;
  font-weight: bold;
}
.OrderV2OptionsPreOptionsError.Text{
  font-size: 12px;
  padding-bottom: 2px;
}

.faq{
  height: 375px;
}
.editorContent{
  display: flex;
  flex-direction: column;

  background: rgba(0,0,0,.03);
  border-radius: 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  scale: 0.9;
  background: #fff;
  border: solid 1px rgba(0,0,0,.05);

  width: calc(100%);

  transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}
@keyframes showContentHide {
  0%{
    opacity: 1;
    scale: 1;
  }
  100%{
    opacity: 0;
    scale: 0.98;
  }
}
@keyframes showContent {
  0%{
    opacity: 0;
    scale: 0.98;
  }
  100%{
    opacity: 1;
    scale: 1;
  }
}
.editorContentSplitter{
  display: flex;
  width: calc(100%);
  height: calc(100%);
}
.editorContentSplitter .ModalSide{
  width: 525px;
  transform: none;
}
.editorContentDetails{
  flex: 1;
  position: relative;
}
.editorContentDetails.Flexed{
  display: flex;
  flex-direction: column;
}
.editorContentDetails.Flexed .modalContentOptions{
  border-top: solid 1px rgba(0,0,0,.05);
  margin-top: 0;
  padding-bottom: 20px;
}
.editorContent.Show{
  opacity: 1;
  height: calc(100%);
  scale: 1;
}
.settingsContentReal{
  display: flex;
  flex-direction: column;
  height: calc(100%);

  transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}
.settingsContentReal.Flexed{
  padding: 0 10px;
}
.settingsContentReal.Show{
  height: 0;
}
.editorEditor{
  flex: 1;
  overflow-y: scroll;
  padding: 20px;
}
.editorEditor *{
  line-height: 22px !important;
}
.editorHeader{
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 10px 0 20px;
  width: calc(100%);
}
.editorHeader.Right{
  justify-content: flex-end;
}
.editorHeader.Float{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 44px;
  left: 60px;
  width: calc(100% - 60px);
}
.editorNavigation{
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 44px;
  background: #f9f9f9;
  border-top: solid 1px rgba(0,0,0,.05);
  border-bottom: solid 1px rgba(0,0,0,.05);
}
.editorNavigation.NoTop{
  border-top: 0;
}
.editorNavigationButton{
  display: flex;
  outline: 0;
  border: 0;
  height: 30px;
  border-radius: 5px;
  margin-right: 5px;
  width: 40px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: solid 1px #e2e2e2;
}
.editorNavigationButton.Selected{
  background: #3b3b3b;
  color: #fff;
}
.editorHeaderTitle{
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
  font-size: 20px;
  letter-spacing: -0.2px;
}
.editorHeaderInput{
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 15px);
  background: transparent;
  height: 32px;
  padding: 0 10px;
  border-radius: 5px 18px 18px 5px;
  font-size: 15px;
  font-weight: bold;
  background: #f2f2f2;
}
.editorHeaderClose{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	width: 32px;
	border-radius: 50%;
  margin-left: 10px;
	cursor: pointer;
	background: #363234;
  color: #fff;
}
.editorHeaderButton{
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  background: #e0197d;

  margin-left: 10px;

  color: #fff;
  border-radius: 16px;

  cursor: pointer;
}
.editorHeaderButtonText{
  margin-right: 10px;
  font-weight: bold;
  line-height: 12px;
}
.SettingsContentContentNavigation{
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 5;
  margin-right: 15px;
  padding-right: 15px;
  border-right: solid 1px rgba(0,0,0,.1);
}
.public-DraftEditor-content{
  min-height: 300px;
}
.modalCouponTitleDivider{
  height: 1px;
  background: rgba(255,255,255,.1);
  width: calc(100%);
  margin: 20px 0;
}
.modalCouponTitle{
	color: rgba(255,255,255,.85);
	font-size: 14px;
	font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
	padding: 0 5px;
}
.modalCouponItem{
  display: flex;
  width: calc(100%);
  margin-top: 8px;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  text-align: left;
  cursor: pointer;
  background: rgba(255,255,255,.15);
}
.modalCouponItem.NoBackground{
  padding: 10px 15px 10px 10px;
  background: transparent !important;
  border-bottom: solid 1px rgba(255,255,255,.1);
  cursor: default;
  margin-top: 0;
}
.modalCouponItem:hover{
  background: rgba(255,255,255,.2);
}
.modalCouponItemImageWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding: 5px;
  width: 55px;
  height: 50px;
  background-color: rgba(255,255,255,.3);
}
.modalCouponItemImage{
  width: calc(100%);
  height: calc(100%);
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.modalCouponItem.End{

}
.modalCouponItemDetailsWrapper{
  flex: 1;
  display: flex;
}
.modalCouponItemDetails{
  flex: 1;
  padding-bottom: 3px;
}
.modalCouponItemDetailsName{
  font-weight: bold;
}
.modalCouponItemDetailsSubWrapper{
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.modalCouponItemDetailsSub{
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 18px;
  font-weight: bold;

  color: #fff;
  border-radius: 5px;

  cursor: pointer;
}
.modalCouponItemDetailsSubText{
  font-weight: bold;
  font-size: 11px;
  margin-top: -1px;
}
.modalCouponItemDetailsSubItemText{
  margin-left: 10px;
}
.modalCouponItemSub{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 150px;
  margin-left: 25px;
  padding-left: 25px;
  border-left: solid 1px rgba(255,255,255,.1);
}
.modalCouponItemSub.Column{
  flex-direction: column;
}
.modalCouponItemSubPrice{
  font-weight: bold;
  text-decoration: line-through;
  color: #fff;
}
.modalCouponItemSubPriceNew{
  margin-top: 5px;
  font-weight: bold;
  color: #e0197d;
}
.modalSideFinalWrapper{
  margin-top: 15px;
  border-radius: 5px;
  background: rgba(255,255,255,.1);
  padding: 5px 10px 15px 10px;
}
.modalSideFinal{
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 10px;
  margin-top: 8px;
}
.modalSideFinal.BorderBottom{
  border-bottom: solid 1px rgba(255,255,255,.1);
  padding-bottom: 10px;
}
.modalSideFinalPre{
  /* width: 150px; */
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
}
.modalSideFinalAmount{
  font-weight: bold;
  color: #fff;
  font-size: 18px;
}
.ModalSideContent .ChatMessageItemText{
  background: #efefef;
}
.ModalSideContent .ChatMessageItemDate{
  color: #efefef;
}
.modalAccountView{
  display: flex;
  align-items: flex-end;
  width: calc(100%);
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px rgba(255,255,255,.1);
}
.modalAccountView.Account .modalAccountAvatar{
  width: 100px;
  height: 100px;
}
.modalAccountView.Account .modalAccountDetails{
}
.modalAccountView.Account .modalAccountDetailsWrapper{
}
.modalAccountDetailsWrapper{
  flex: 1;
  display: flex;
  align-items: center;
}
.modalAccountDetailsOptions{
  min-width: 150px;
}
.modalAccountDetailsOptions .ModalSideContentTwin{
  margin-bottom: 0;
}
.modalAccountAvatar{
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
  background-color: rgba(255,255,255,.1);
}
.modalAccountDetails{
  flex: 1;
  margin-left: 5px;
}
.modalAccountDetailsName{
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.modalAccountDetailsPhone{
  color: #efefef;
  margin-top: 2px;
}
.SettingsSticker{
  width: 150px;
  height: 150px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 10px;
  background: #e0197d;

  position: relative;
}
.SettingsStickerClear{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;

  position: absolute;
  top: -10px;
  right: -10px;

  z-index: 5;
  background: #d63636;
  color: #fff;
}
.SettingsStickerInput{
  text-align: center;
}
.SettingsStickerInput::placeholder{
  color: rgba(255,255,255,.75) !important;
}
.SettingsStickerInput:nth-child(1){
  margin-top: 0;
  width: 70%;
}
.SettingsStickerInput:nth-child(2){
  margin-top: 5px;
}
.SettingsStickerInput:nth-child(3){
  margin-top: 5px;
  width: 70%;
}
.SettingsMessageText{
  display: flex;
  padding: 20px 25px;
  color: #7b7b7b;
}
.SyncPreviewWrapper{
  background: rgba(255,255,255,.1);
  padding: 5px 15px;
  border-radius: 5px;
}
.SyncPreviewItem{
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.SyncPreviewItemKey{
  flex: 1;
  color: rgba(255,255,255,.85);
}
.SyncPreviewItemValue{
  font-weight: bold;
  color: #fff;
}
.SyncColumnsWrapper{

}
.SyncColumns{
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: scroll;

  padding: 0 30px;
  padding-bottom: 20px;
  margin: 0 -30px;
  margin-top: 10px;
  margin-bottom: -10px;
}
.SyncColumnsItem{
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 7.5px;
  padding: 0 12px;
  border-radius: 5px;
  background: rgba(255,255,255,.25);
  color: #fff;
  font-weight: bold;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  cursor: move;
}
.SyncColumnsItem.Disabled{
  background: #d63636;
}
.SyncColumnsItemClose{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: -4px;
}
.SyncColumnsItemHint{
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 5px;
  margin-bottom: -5px;
  min-height: 30px;
}
.SyncColumnsItemHintText{
  color: rgba(255,255,255,.6);
  font-size: 13px;
}
.SyncColumnsItemHint .ModalSideContentImageDetailsOptionsButton{
  width: 30px;
  height: 30px;
  justify-content: center;
  padding: 0;
  margin-right: 10px;
  background: #7b7b7b;
}
.SyncColumnsItemHint .ModalSideContentImageDetailsOptionsButton:hover{
  background: #8b8b8b;
}

.settingsHeaderOptionsView{
  display: flex;
  height: calc(100%);
}
.settingsHeaderOptions{
  display: flex;
  align-items: center;
  height: calc(100%);

  transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}
.settingsHeaderOptionsView .OrderItemOptionsButton{
  scale: .75;
  opacity: 0;

  transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}
.settingsHeaderOptionsView.Show .OrderItemOptionsButton{
  scale: 1;
  opacity: 1;
}
.SettingsContent .HeaderNavSearchWrapper{
  border-left: none;
}
.SettingsContent .HeaderNavSearchWrapper:focus-within .HeaderNavSearch{
  width: 350px !important;
}
.SyncProductsWrapper{
  position: relative;
}

.DashDashBlockOptionsFlipper{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 2px;
  height: 36px;
  border-radius: 10px;
  background: #fff;
  color: #3b3b3b;
  width: calc(100%);
  cursor: pointer;
  margin-left: 15px;

  transition: all 150ms ease-in-out;
}
.DashDashBlockDate{
  display: flex;
  align-items: center;
  background: #3776df;
  color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 0 12px;
  font-weight: 500;
  height: 32px;

  position: absolute;
  top: 37px;
  left: 10px;
  border-bottom: solid 1px #efefef;

  box-shadow: 0 0 40px rgba(0,0,0,.075);
}
.DashDashBlockOptionsFlipperNum{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: #f2f2f2;
  cursor: not-allowed;
  padding: 0 12px;
  border-radius: 8px;
  opacity: .5;
  user-select: none;
}
.DashDashBlockOptionsFlipperNum.Active{
  cursor: pointer;
  opacity: 1;
}
.DashDashBlockOptionsFlipperNum.Active:hover{
  background: #f6f6f6;
}
.DashDashBlockOptionsFlipperNum.Active:active{
  background: #efefef;
}
.DashDashBlockOptionsFlipperNumText{
  margin: 0 10px;
  font-weight: bold;
}
.DashDashBlockOptionsFlipperTextView{
  padding: 0 15px;
  position: relative;
}
.DashDashBlockOptionsFlipperReset{
  position: absolute;
  display: flex;
  align-items: center;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  height: 26px;
  padding: 0 13px 0 11px;
  white-space: nowrap;
  border-radius: 13px;
  background: #fff;
  color: #e8242e;
  border: solid 1px #f2f2f2;
  box-shadow: 0 0 40px rgba(0,0,0,.1);

  font-size: 10px;
}
.DashDashBlockOptionsFlipperResetText{
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
}
.DashDashBlockOptionsFlipperText{
  font-weight: bold;
  font-size: 15px;
}

.SettingsAnalyticsDateWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
}
.SettingsAnalyticsDate{
  display: flex;
  height: 30px;
  border-radius: 0 0 5px 5px;
  align-items: center;
  justify-content: center; 
  padding: 0 10px;
  background: #e0197d;
  color: #fff;
}
.SettingsAnalytics{
  height: calc(100%);
  overflow-y: scroll;
  margin-right: -25px;
  padding-right: 25px;;
  padding-bottom: 10px;
}
.DashDashChart{
  width: calc(100%);
  position: relative;
}
.DashDashChart.MarginTop{
  margin-top: 20px;
}
.DashDashTwin{
  display: flex;
  margin-top: 10px;
}
.DashDashTwinItem{
  flex: 1;
  margin: 5px;
  padding: 15px 17px 5px 17px;
  border-radius: 5px;
  background: #f8f8f8;
}
.DashDashTwinItemTitle{
  font-weight: bold;
  font-size: 16px;
}
.DashDashTwinItemItems{
  margin-top: 10px;
  padding: 0 2px;
}

.DashDashTwinItemItem{
  padding: 10px 0;
}
.DashDashTwinItemItem.Item{
  border-bottom: solid 1px #efefef;
}
.DashDashTwinItemItem.Item .DashDashTwinItemItemTimes{
  margin-top: 2px;
}
.DashDashTwinItemItemDetails{
  display: flex;
  align-items: flex-end;
}
.DashDashTwinItemItemText{
  flex: 1;
}
.DashDashTwinItemItemTimes{
  font-weight: bold;
  font-size: 18px;
}
.DashDashTwinItemItemBar{
  background: #f2f2f2;
  margin-top: 8px;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  width: calc(100%);
  position: relative;
}
.DashDashTwinItemItemBarValue{
  height: calc(100%);
  background: #e0197d;
  opacity: .5;
}