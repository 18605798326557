.DropDown{
  position: relative;
}
.ModalSideContent .DropDownSelected{
  max-width: none;
}
.DropDownSelected{
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  padding: 0 10px;
  margin-top: 10px;
  color: #fefefe;
  background: rgba(255,255,255,.2);
  cursor: pointer;
  font-weight: bold;

  /* max-width: 270px; */
  max-width: 470px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DropDownSelected:hover{
  background: rgba(255,255,255,.25);
}
.DropDownDropdownWrapper{
  display: block;
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
}
.DropDownDropdownSearchWrapper{
  background: #646163;
  padding: 5px 5px;
  border-bottom: solid 1px rgba(0,0,0,.1);
}
.DropDownDropdownSearch .ModalSideContentInput input{
}
.DropDownDropdownSearch{
  margin-top: 0 !important;
}
.DropDownDropdown{
  display: block;

  max-height: 200px;
  background: #646163;
  box-shadow: 0 10px 20px rgba(0,0,0,.15);
  border-radius: 5px;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.DropDownDropdown.Search{
  border-radius: 0 0 5px 5px;
}
.DropDownDropdownSearch::placeholder{
	color: rgba(255,255,255,.75) !important;
}
.DropDownDropdownItem{
  display: flex;
  align-items: center;

  min-height: 30px;
  padding: 5px 10px 6px 10px;
  cursor: pointer;

  color: #fefefe;
}
.DropDownDropdownItemText{
  flex: 1;
}
.DropDownDropdownItem.Canceled{
  background: rgba(214, 54, 54, .75);
}
.DropDownDropdownItem.Canceled:hover{
  background: rgba(214, 54, 54, .85);
}
.DropDownDropdownItem.Canceled .DropDownDropdownItemText{
  color: #fff;
  font-weight: bold;
}
.DropDownDropdownItemCircle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #47bb3c;
}
.DropDownDropdownItem:hover{
  background: rgba(255,255,255,.15);
  color: #fff;
}
