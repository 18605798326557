.Info{
  min-height: 600px;
}
.InfoSplitter{
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
}
.InfoSplitterSide{
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  padding-right: 25px;
  border-right: solid 1px rgba(0,0,0,.05);
  width: 350px;
  min-height: 400px;
}
.InfoSplitterSideTitle{
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.3px;
  padding: 0 15px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px rgba(0,0,0,.1);
}
.InfoSplitterSideItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  padding: 0 20px;
  border-radius: 2px;
  cursor: pointer;
  border-bottom: solid 1px rgba(0,0,0,.03);
  background: rgba(255,255,255,.5);
}
.InfoSplitterSideItem:hover{
  background: rgba(255,255,255,.75);
}
.InfoSplitterSideItem.Selected{
  color: #e0197d;
  background: rgba(255,255,255,1);
}
.InfoSplitterSideItemTitle{
  flex: 1 1;
  font-weight: bold;
  font-size: 16px;
  opacity: .75;
  letter-spacing: -0.3px;
}
.InfoSplitterInfo{
  flex: 1;
}
.BlockItemWrapper{
  padding: 20px 0;
}
.BlockItemView{
  margin-bottom: 30px;
}
.BlockItemView:last-child{
  margin-bottom: 0;
}
.BlockItemTitle{
  font-weight: bold;
  font-size: 25px;
  letter-spacing: -0.5px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px rgba(0,0,0,.1);
}
.BlockItemImage{
  display: flex;
  align-items: center;
  margin-left: -5px;
  width:calc(100%);
}
.BlockItemImage.Full{
  margin-bottom: 15px;
  margin-left: 0;
  flex-direction: column;
}
.BlockItemImage.Full>img{
  display: block;
  border-radius: 5px;
  height: 550px;
  max-height: none;
  width:calc(100%);
  background-size: cover;
  background-position: center;
  background-color: rgba(255,255,255,.5);
  transition: all 600ms ease-in-out;
  object-fit: cover;
}
.BlockItemImage>img{
  display: inline-block;
  max-height: 200px;
  background: #efefef;
  margin-right: 10px;
}
.BlockItemImage>img:last-child{
  margin-right: 0;
}
.BlockItemText{

}
